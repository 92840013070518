/* eslint-disable no-underscore-dangle, max-classes-per-file */

'use strict';

define('vb/private/stateManagement/router/vbRouter',[
  'knockout',
  'signals',
  'vb/private/log',
], (ko, signals, Log) => {
  const log = Log.getLogger('/vb/stateManagement/vbRouter');

  /**
   * Hold the value of the VbRouter.defaults.baseUrl property.
   * @private
   * @type {!string}
   */
  let _baseUrlProp = '/';

  /**
   * Hold the title before being modified by router
   * @private
   * @type {?string}
   */
  let _originalTitle;

  /**
   * The default name for the root instance.
   * @private
   * @const
   * @type {string}
   */
  const _DEFAULT_ROOT_NAME = 'root';

  /**
   * The name of the request param for bookmarkable data.
   * @private
   * @const
   * @type {string}
   */
  const _ROUTER_PARAM = 'vb_Router';
  /**
   * The separator used to build the title from router labels.
   * @private
   * @const
   * @type {string}
   */
  const _TITLE_SEP = ' | ';

  /**
   * Name of the window event used to listen to the browser history changes
   * @private
   * @const
   * @type {string}
   */
  const _POPSTATE = 'popstate';

  /**
   * Name of the property of the object in the Promise returned by go() or sync()
   * @private
   * @const
   * @type {string}
   */
  const _HAS_CHANGED = 'hasChanged';

  /**
   * Object commonly used as return value for go() or sync()
   * @private
   * @const
   * @type {{hasChanged:boolean}}
   */
  const _NO_CHANGE_OBJECT = { hasChanged: false };

  /**
   * Flag set to true when VbRouter is initialized
   * @private
   * @type {boolean}
   */
  let _initialized = false;

  /**
   * Hold the leftover path when using ojModule and the child router
   * doesn't exist yet.
   * @private
   * @type {string|undefined}
   */
  let _deferredPath;

  /**
   * A queue to hold unprocessed transitions
   * @private
   * @type {Array.<Object>}
   */
  let _transitionQueue = [];

  /**
   * A promise that resolve when all transition in the queue is resolved.
   * @private
   */
  let _queuePromise;

  /**
   * A shortcut to access window.location
   * @private
   */
  const _location = window.location;

  /**
   * The name of the object containing state parameters.
   * @private
   */
  const _parametersValue = 'parameters';

  /**
   * Flag set to true when the router is in the process of updating the states
   * involved in the current transition
   * @private
   * @type {boolean}
   */
  let _updating = false;

  /**
   * The instance of the root router.
   * @private
   * @const
   * @type {!VbRouter}
   */
  let _rootRouter;

  class RouterDefaults {
    static _getInitializeError(param) {
      return new Error(`Incorrect operation. Cannot change the ${param} after calling sync() or go().`);
    }

    static get urlAdapter() {
      return _rootRouter.urlAdapter;
    }

    static set urlAdapter(urlAdapter) {
      if (_initialized) {
        throw RouterDefaults._getInitializeError('URL adapter');
      }
      _rootRouter.urlAdapter = urlAdapter;
    }

    static get baseUrl() {
      return _baseUrlProp;
    }

    static set baseUrl(baseUrl) {
      if (_initialized) {
        throw RouterDefaults._getInitializeError('base URL');
      }
      if (!baseUrl) {
        _baseUrlProp = '/';
      } else {
        // Remove anything after ? or #
        _baseUrlProp = baseUrl.match(/[^?#]+/)[0];
      }

      if (_rootRouter.urlAdapter) {
        _rootRouter.urlAdapter.init(_baseUrlProp);
      }
    }

    static get rootInstanceName() {
      return _rootRouter._name;
    }

    static set rootInstanceName(rootName) {
      if (_initialized) {
        throw RouterDefaults._getInitializeError('name of the root instance');
      }

      if (typeof rootName === 'string') {
        _rootRouter._name = encodeURIComponent(rootName.trim());
      }
    }
  }

  /**
   * Alias for property VbRouter.transitionedToState
   * @private
   */
  const _transitionedToState = new signals.Signal();

  /**
   * Return key/value object of query parameters.
   * @private
   * @return {!Object.<string, string>}
   */
  function parseQueryParam(queryString) {
    const params = {};

    // Remove starting '?'
    const trimmedQueryString = queryString.split('?')[1];

    if (trimmedQueryString) {
      const keyValPairs = trimmedQueryString.split('&');
      keyValPairs.forEach((pair) => {
        const parts = pair.split(/=(.+)?/);
        const key = parts[0];

        if (key.length) {
          const value = parts[1] && decodeURIComponent(parts[1]);
          params[key] = value;
        }
      });
    }

    return params;
  }

  /**
   * Convert forward slash characters used for paths to a character which doesn't
   * require URL encoding (to save space in the URL) and is unambiguous from the
   * path separator.
   * @param       {string} value The string whose slash characters are to be encoded.
   * @return      {string}      An encoded form of the string
   * @private
   */
  function _encodeSlash(value) {
    let enc = value;
    if (enc && enc.replace) {
      enc = enc.replace(/~/g, '~0');
      enc = enc.replace(/\//g, '~1');
    }
    return enc;
  }

  /**
   * Given an encoded string from _encodeSlash, decode the characters to restore
   * the value to its original form.
   * @param       {string} value The string whose encoded slash values will be decoded.
   * @return      {string}      The decoded form of the string
   * @private
   */
  function _decodeSlash(value) {
    let dec = value;
    if (dec && dec.replace) {
      dec = dec.replace(/~1/g, '/');
      dec = dec.replace(/~0/g, '~');
    }
    return dec;
  }

  /**
   * Takes a path of segment separated by / and returns an array of segments
   * @param  {string=} path a path of segment separated by /
   * @return {!Array} an array of segment
   */
  function _getSegments(path) {
    return path ? path.split('/') : [];
  }

  /**
   * Returns the first segment of a path separated by /
   * @param  {string=} id
   * @return {string}
   */
  function _getShortId(id) {
    const segment = _getSegments(id)[0];
    return _decodeSlash(segment);
  }

  /**
   * Replace the state param in the URL.
   * @private
   * @param {!string} url the url to which the param will be added
   * @return {!string} the URL with the new state param
   */
  function putStateParam(url) {
    let startSegment;
    let endSegment;
    const start = url.indexOf(_ROUTER_PARAM);

    if (start !== -1) {
      let end = url.indexOf('&', start);
      if (end === -1) {
        end = url.length;
      }

      startSegment = url.substring(0, start);
      endSegment = url.substr(end);
    } else {
      startSegment = url + ((url.indexOf('?') === -1) ? '?' : '&');
      endSegment = '';
    }

    // Remove the '?' or '&'
    startSegment = startSegment.substring(0, startSegment.length - 1);

    return startSegment + endSegment;
  }

  /**
   * Build an URL by replacing portion of the existing URL. Portion that can be replaces are
   * pathname and search field. Use the extraState to build the new state param.
   * @private
   * @param  {!Object} pieces
   * @param  {!Object.<string, Object>} extraState
   * @return {!string}
   */
  function _buildUrl(pieces, extraState) {
    const parser = document.createElement('a');
    parser.href = _location.href;

    if (pieces.search !== undefined) {
      parser.search = pieces.search;
    }

    if (pieces.pathname !== undefined) {
      parser.pathname = pieces.pathname;
    }

    // Add or replace the existing state param
    parser.search = putStateParam(parser.search, extraState);

    // Remove trailing ?
    let url = parser.href;
    if (url.slice(-1) === '?') {
      url = url.slice(0, -1);
    }

    return url;
  }

  /**
   * Only keep changes where the value doesn't match the current router state
   * @private
   * @param {!Array.<_StateChange>} changes
   * @return {!Array.<_StateChange>}
   */
  function _filterNewState(changes) {
    const newChanges = changes.filter((change) => (change.value !== change.router._stateId()));

    if (log.isFiner) {
      log.finer('Potential changes are:');
      newChanges.forEach((change) => {
        log.finer('   { router:', change.router && change.router._getRouterFullName(),
          'value:', change.value, '}');
      });
    }

    return newChanges;
  }

  /**
   * Update the bookmarkable data
   * @private
   * @this {!Object.<string, Object>}
   * @param {?Object} change
   */
  function _updateBookmarkableData(change) {
    const ex = this[change.router._name];
    if (ex !== undefined) {
      // eslint-disable-next-line no-param-reassign
      change.router._extra = ex;
    }
  }

  /**
   * Return true if the current transition is cancelled.
   * See queuing of transaction in _queueTransaction
   * @private
   * @return {boolean}
   */
  function isTransitionCancelled() {
    return (_transitionQueue[0] && _transitionQueue[0].cancel);
  }

  /**
   * Decompress and decode the state param from the URL.  This is used for bookmarkable data.
   * @private
   * @param {!string} param
   * @return {!Object.<string, Object>}
   * @throws An error if parsing fails or format is invalid.
   */
  function decodeStateParam(param) {
    // First character is the compression type. Right now only 0 and 1 are supported.
    // 0 for no compression, 1 for LZW
    const compressionType = param.charAt(0);

    let decodedParam = param.slice(1);

    if (compressionType === '0') {
      decodedParam = decodeURIComponent(decodedParam);
    } else {
      throw new Error('Error retrieving bookmarkable data. Format is invalid');
    }

    const extraState = /** @type {!Object.<string, Object>} */ (JSON.parse(decodedParam));

    if (log.isFiner) {
      log.finer('Bookmarkable data:');
      const names = Object.keys(extraState);
      for (let i = 0; i < names.length; i++) {
        const name = names[i];
        log.finer('   { router:', name, 'value:', extraState[name], '}');
      }
    }

    return extraState;
  }

  /**
   * An object use to represent a change in a RouterState
   */
  class _StateChange {
    /**
     * Constructs a new instance.
     *
     * @param {!VbRouter} router
     * @param {string=} value
     */
    constructor(router, value) {
      this.router = router;
      this.value = value; // the value is also the stateId
    }

    /**
     * Returns the RouterState object for the state id in this change object
     * @return {VbRouterState} the RouterState matching the value
     * @private
     */
    getState() {
      if (!this.state) {
        if (this.value) {
          this.state = this.router._getStateFromId(_getShortId(this.value));
        }
      }

      return this.state;
    }

    /**
     * Store a state parameter value by appending it to the value as a path
     * @param {string=} value
     * @private
     */
    addParameter(value) {
      if (value) {
        this.value = `${this.value}/${value}`;
      }
    }
  }

  /**
   * Build a page title using the label of the current child routers state
   * @private
   * @param  {VbRouter|undefined} router
   * @return {!{segment: string, title: string}}
   */
  function _buildTitle(router) {
    if (!router) {
      return { title: '', segment: '' };
    }

    // Recurse leaf first
    const titleInfo = _buildTitle(router._getChildRouter(router._stateId()));

    // If we don't have a title yet, build one.
    if (titleInfo.title === '') {
      const state = router._currentState();
      if (state) {
        // If a title property is present, it has precedence.
        let title = state._title;
        if (title !== undefined) {
          if (typeof title === 'function') {
            title = title();
          }
          titleInfo.title = String(title);
        } else {
          // Otherwise, compose the title with the label
          title = state._label;
          if (title !== undefined) {
            title = String(title);
            // Append existing segment
            if (titleInfo.segment !== '') {
              title += _TITLE_SEP + titleInfo.segment;
            }
            titleInfo.segment = title;
          }
        }
      }
    }

    return titleInfo;
  }

  /**
   * Takes an array of changes from parsing and appends other changes needed to be done.
   * 1) All cascading default state
   * 2) All the state that need to become undefined
   * @private
   * @param {!Array.<_StateChange>} states
   * @return {!Array.<_StateChange>}
   */
  function _appendOtherChanges(states, rootRouter) {
    const lastItem = states[states.length - 1];
    let router;
    let value;

    // If there is a state, starts with it
    if (lastItem) {
      router = lastItem.router;
      value = _getShortId(lastItem.value);
    } else {
      // Otherwise, starts at the root router
      router = _rootRouter;
      value = _rootRouter._defaultStateId;
      if (value) {
        states.push(new _StateChange(router, value));
      }
    }

    // Append all the default states all the way to the leaf router
    router = router._getChildRouter(value);
    while (router) {
      value = router._defaultStateId;
      if (value) {
        states.push(new _StateChange(router, value));
      }
      router = router._getChildRouter(value);
    }

    // Build an array of all the state to become undefined due to the parent state changing. The
    // order of execution is leaf first.
    const undef = [];
    if (states[0] && states[0].router === rootRouter) {
      const undefStates = rootRouter._buildAllUndefinedState(); // First build an array of undefined state

      undefStates.forEach((select, i) => {
        const change = states[i];

        // Only insert change for a different router since the undef change will already happen when
        // a router transition to a different state.
        if (!change || select.router !== change.router) {
          undef.unshift(select);
        }
      });
    }

    // The order of execution is exit(undef) from leaf to root followed by enter from root to leaf
    return undef.concat(states);
  }

  /**
   * Build an array of objects by visiting the parent hierarchy.
   * Each element of the array represent the state of a router.
   * @private
   * @param {!VbRouter} router
   * @param {!string} path
   * @return {!Array.<_StateChange>}
   */
  function _buildStateFromPath(router, path) {
    const newStates = [];
    const routers = [];
    let rt = router;
    const parts = _getSegments(path);
    let parent;
    let parentStateId;
    let state;
    let stateChange;
    let pi = 0;

    // Since path is absolute, it always starts with '/', so remove the first
    // element (empty string)
    parts.splice(0, 1);

    // Build an array of routers, from the root to the current one.
    while (rt) {
      routers.unshift(rt);
      rt = rt._parentRouter;
    }

    // Traverse path and routers simultaneously.
    for (let sId = parts.shift(); sId; sId = parts.shift()) {
      if (state) {
        const pName = state._paramOrder[pi];

        // If state has parameters, save the state Id as the parameter value
        if (pName) {
          stateChange.addParameter(sId);
          pi += 1;
        } else {
          // Otherwise, reset parameter index
          pi = 0;
        }
      }

      if (!state || pi === 0) {
        rt = routers.shift();

        if (!rt) {
          rt = parent._findRouterForStateId(sId, parentStateId);

          // Router doesn't exist, save deferredPath and stop
          if (!rt) {
            _deferredPath = path;
            return newStates;
          }
        }

        stateChange = new _StateChange(rt, sId);
        state = stateChange.getState();
        if (!state) {
          throw new Error(`Invalid path "${path}". State id "${sId}" does not exist on router "${rt._name}".`);
        }

        newStates.push(stateChange);
        parent = rt;
        parentStateId = sId;
      }
    }

    return newStates;
  }

  /**
   * Chain callback into a sequence if it's a function.
   * @private
   * @param  {(function (): (?)|undefined)} callback
   * @param  {IThenable.<?>|null}           sequence
   * @return {IThenable.<?>|null}
   */
  function _chainCallback(callback, sequence) {
    // Check if we can enter this new state by executing the callback.
    // If the callback is a function, chain it.
    if (typeof callback === 'function') {
      // Check if this is the start of the chain
      if (sequence === null) {
        return Promise.resolve(callback());
      }

      // Only test the next state if the previous promise return true
      return sequence.then((result) => result && callback());
    }

    return sequence;
  }

  /**
   * Return a promise returning an object with an array of all the changes and the origin if all of
   * the new state in the allChanges array can enter.
   * @private
   * @param {!Array.<_StateChange>} allChanges
   * @param {string=} origin a string specifying the origin of the transition ('sync', 'popState')
   * @return {!Promise} a promise returning an object with an array of all the changes and the origin.
   */
  function _canEnter(allChanges, origin) {
    if (isTransitionCancelled()) {
      return Promise.resolve();
    }

    log.finer('Start _canEnter.');

    let sequence = null;

    // Build a chain of canEnter promise for each state in the array of changes
    allChanges.forEach((change) => {
      const newState = change.getState();

      // It is allowed to transition to an undefined state, but no state
      // callback need to be executed.
      if (newState) {
        sequence = _chainCallback(newState._canEnter, sequence);
      }
    });

    if (sequence === null) {
      return Promise.resolve({ allChanges, origin });
    }

    return sequence.then((result) => {
      if (result && !isTransitionCancelled()) {
        return { allChanges, origin };
      }

      return undefined;
    });
  }

  /**
   * Update the state of a router with the new value.
   * @private
   * @param {{value:string, router:!VbRouter}} change
   * @param {string | undefined} origin
   */
  function _update(change, origin) {
    const oldState = change.router._getStateFromId(_getShortId(change.router._stateId()));
    const newState = change.getState();

    return Promise.resolve()
      .then(() => {
        if (log.isFiner) {
          log.finer('Updating state of', change.router._getRouterFullName(), 'to', change.value);
        }
      })
      // Execute exit on the current state
      .then(oldState ? oldState._exit : undefined)
      .then(() => {
        const rt = change.router;
        let goingBackward = false;

        // Are we going back to the previous state?
        if (origin === 'popState') {
          const length = rt._navHistory.length;
          let i;
          // Are we going back to the previous state?
          for (i = length - 1; i >= 0; i--) {
            if (rt._navHistory[i] === change.value) {
              goingBackward = true;
              // Delete all elements up the one matching
              rt._navHistory.splice(i, length - i);
              break;
            }
          }

          // Back only if going back 1
          if ((length - i) === 1) {
            rt._navigationType = 'back';
          }
        }

        if (!goingBackward) {
          rt._navigationType = undefined;
          rt._navHistory.push(_getShortId(rt._stateId()));
        }

        // Update the parameters
        if (change.value && newState) {
          const segments = _getSegments(change.value);
          newState._paramOrder.forEach((name, ii) => {
            const newValue = _decodeSlash(segments[ii + 1]);
            const oldValue = newState[_parametersValue][name];

            // Update the parameter value
            if (newValue !== oldValue) {
              newState[_parametersValue][name] = newValue;
            }
          });

          // TODO: Should we execute a callback for case where state doesn't change
          //      and using a configure with function? or disable for function configure?
        }

        // Change the value of the stateId
        rt._stateId(change.value);
      })
      // Execute enter on the new state
      .then(newState && newState._enter);
  }

  /**
   * Update the state of all routers in the change array.
   * @private
   * @param {Object} updateObj
   * @return {!Promise}
   */
  function _updateAll(updateObj) {
    if (!updateObj) {
      return Promise.resolve(_NO_CHANGE_OBJECT);
    }

    let sequence = Promise.resolve().then(() => {
      log.finer('Entering _updateAll.');
      _updating = true;
    });

    let oldState;
    const allChanges = updateObj.allChanges;
    allChanges.forEach((change) => {
      oldState = change.router.currentState.peek();
      sequence = sequence.then(() => {
        if (!isTransitionCancelled()) {
          return _update(change, updateObj.origin);
        }
        return undefined;
      });
    });

    return sequence
      .then(() => {
        let hasChanged = false;
        let router;
        let newState;
        if (allChanges.length) {
          hasChanged = !isTransitionCancelled();
          /*
           * Pass the last state of a multi-state transition as the new state to
           * which we're transitioning.
           */
          const change = allChanges[allChanges.length - 1];
          router = change.router;
          newState = change.state;
        }
        log.finer('_updateAll returns', hasChanged);
        return {
          hasChanged,
          router,
          oldState,
          newState,
        };
      })
      .finally(() => {
        _updating = false;
      });
  }

  /**
   * Update the state using the current URL.
   * @private
   * @param {Object} transition An object with properties describing the transition.
   * @return a Promise that resolves when the routers state are updated
   */
  function parseAndUpdate(transition = { rootRouter: _rootRouter }) {
    return Promise.resolve().then(() => {
      let allChanges = transition.rootRouter.urlAdapter.parse(transition);

      // Only keep changes where the value doesn't match the router state
      allChanges = _filterNewState(allChanges);

      return _canEnter(allChanges, transition.origin).then(_updateAll);
    });
  }

  /**
   * Function use to handle the popstate event.
   */
  function popStateEventListener({ state }) {
    // eslint-disable-next-line no-use-before-define
    const rootRouter = VbRootRouter.rootInstance;
    if (rootRouter) {
      rootRouter.handlePopState(state);
    }
  }

  /**
   * Initialize the default for VbRouter if needed. Dispose on the root router
   * will de-initialize.
   * @private
   */
  function _initialize() {
    if (!_initialized) {
      _originalTitle = window.document.title;

      /**
       * Listen to URL changes caused by back/forward button
       * using the popstate event. Call handlePopState to dispatch the change of URL.
       */
      window.addEventListener(_POPSTATE, popStateEventListener, false);

      log.finer('Initializing rootInstance.');
      log.finer('Base URL is', _baseUrlProp);
      log.finer('Current URL is', _location.href);

      _initialized = true;
    }
  }

  /*------------------------------------------------------------------------------
    URL Apdaters section
    ------------------------------------------------------------------------------*/

  /**
   * Base class for all the URL adapter.
   *
   */
  class UrlAdapter {
    // eslint-disable-next-line class-methods-use-this
    init() {
    }

    /**
     * Construct an array of states where each item is an object made of a router and
     * the new state for it.
     * @param {Object} transition An object with properties describing the transition.
     * @return {!Array.<_StateChange>}
     * @throws Error when parsing of router param fails.
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    parse(transition) {
    }

    // eslint-disable-next-line class-methods-use-this
    buildUrlFromStates() {
    }

    static getChangesFromSegments(rootRouter, segments) {
      let changes = [];
      let router = rootRouter;

      while (router) {
        const value = segments.shift();
        if (!value) {
          break;
        }

        const stateChange = new _StateChange(router, value);

        const state = stateChange.getState();

        // If this state has parameters, the following segments are parameter values
        if (state) {
          state._paramOrder.forEach(() => {
            // Retrieve the next segment and use it for the parameter value
            stateChange.addParameter(segments.shift());
          });
        }

        changes.push(stateChange);
        // eslint-disable-next-line no-param-reassign
        router = router._getChildRouter(value);
      }

      changes = _appendOtherChanges(changes, rootRouter);

      return changes;
    }
  }

  /**
   * An adapter used to manage the router state using the browser history instead of the URL
   * This is used by the SwitcherRootRouter.
   */
  class HistoryAdapter extends UrlAdapter {
    constructor(basePagePath) {
      super();

      this._basePagePath = basePagePath;
    }

    getPath() {
      let path;

      // To retrieve the portion of the path representing the routers state,
      // remove the base portion of the path.
      const state = window.history.state;
      const vbState = state && state.vbState;
      const pagePath = vbState && vbState.pagePath;

      if (pagePath) {
        path = pagePath.replace(this._basePagePath, '');
      }

      return path;
    }

    /**
     * Construct an array of states where each item is an object made of a router and
     * the new state for it.
     * @param {Object} transition An object with properties describing the transition.
     * @return {!Array.<_StateChange>}
     * @throws Error when parsing of router param fails.
     */
    parse(transition) {
      let changes = [];

      const path = this.getPath();
      if (path) {
        changes = UrlAdapter.getChangesFromSegments(transition.rootRouter, _getSegments(path));
      }

      return changes;
    }
  }

  /**
   * Url adapter used by the {@link VbRouter} to manage URL in the form of
   * <code class="prettyprint">/book/chapter2</code>.<br>The UrlPathAdapter is the default
   * adapter used by the {@link VbRouter|router} as it makes more human-readable URLs,
   * is user-friendly, and less likely to exceed the maximum charaacter limit in the
   * browser URL.
   * <br>Since this adapter generates path URLs, it's advisable that your application
   * be able to restore the page should the user bookmark or reload the page.  For
   * instance, given the URL <code class="prettyprint">/book/chapter2</code>, your
   * application server ought to serve up content for "chapter2" if the user should
   * bookmark or reload the page.  If that's not possible, then consider using the
   * {@link VbRouter.urlParamAdapter|urlParamAdapter}.
   * <br>There are two available URL adapters,
   * this one and the {@link VbRouter.urlParamAdapter|urlParamAdapter}.<br>To change
   * the URL adapter, use the {@link VbRouter.defaults|urlAdapter} property.
   * @see VbRouter.urlParamAdapter
   * @see VbRouter.defaults
   */
  class UrlPathAdapter extends UrlAdapter {
    constructor() {
      super();

      /**
       * Variable to store the base path. This is used to retrieve the portion of the path
       * representing the routers state.
       * @type {!string}
       */
      this._basePath = null;

      this.init(_baseUrlProp);
    }

    /**
     * Initialize the adapter given the baseUrlProp.
     * For the urlPathAdapter, retrieve the potential file name to handle application with
     * index.html in their URL.
     * @param {!string} baseUrlProp the value of VbRouter.defaults.baseUrl
     */
    init(baseUrlProp) {
      // Use the browser parser to get the pathname. It works with absolute or relative URL.
      const parser = document.createElement('a');
      parser.href = baseUrlProp;

      let path = parser.pathname;
      path = path.replace(/^([^/])/, '/$1'); // Should always start with slash (for IE)

      // Normalize the base path. Always ends with a '/'
      if (path.slice(-1) !== '/') {
        path += '/';
      }

      this._basePath = path;
    }

    getPath() {
      // To retrieve the portion of the path representing the routers state,
      // remove the base portion of the path.
      return _location.pathname.replace(this._basePath, '');
    }

    /**
     * Construct an array of states where each item is an object made of a router and
     * the new state for it.
     * @param {Object} transition An object with properties describing the transition.
     * @return {!Array.<_StateChange>}
     * @throws Error when parsing of router param fails.
     */
    parse(transition) {
      const path = this.getPath();
      const segments = _getSegments(decodeURIComponent(path)).map(_decodeSlash);

      log.finer('Parsing:', path);
      const changes = UrlAdapter.getChangesFromSegments(transition.rootRouter, segments);

      // Retrieve the extra state from request param oj_Router
      let stateStr = _location.search.split(`${_ROUTER_PARAM}=`)[1];
      if (stateStr) {
        stateStr = stateStr.split('&')[0];
        if (stateStr) {
          changes.forEach(_updateBookmarkableData, decodeStateParam(stateStr));
        }
      }

      return changes;
    }

    /**
     * Given an ordered array of states, build the URL representing all
     * the states.
     * Never starts with a '/': "book"  "book/chapter2"
     * @param {!Array.<_StateChange>} newStates
     * @return {!string} the URL representing the states
     */
    buildUrlFromStates(newStates) {
      let canDefault = false;
      let pathname = '';
      const extraState = {}; // Compound object of all extra states

      // Build the new URL by walking the array of states backward in order to eliminate
      // the default state from the URL. As soon as a value is not the default, stops the removal.
      for (let ns = newStates.pop(); ns; ns = newStates.pop()) {
        if (ns.value) {
          if (canDefault || (ns.value !== ns.router._defaultStateId)) {
            pathname = pathname ? `${ns.value}/${pathname}` : ns.value;
            canDefault = true;
          }
        }

        // Build an object made of the extra data of each router
        if (ns.router._extra !== undefined) {
          extraState[ns.router._name] = ns.router._extra;
        }
      }

      return _buildUrl({ pathname: this._basePath + pathname }, extraState);
    }
  }

  /**
   *  Url adapter used by the {@link VbRouter} to manage URL in the form of
   * <code class="prettyprint">/index.html?root=book&book=chapter2</code>.  This adapter
   * can be used if the {@link VbRouter.urlPathAdapter|urlPathAdapter} doesn't meet
   * the application's needs.
   * <br>This adapter is well-suited for single-page applications whose entry point
   * is always a single document, i.e., "index.html" which restores its router state
   * from additional parameters.  The router state is encoded as URL parameters and
   * then restored after the page is loaded.  This is ideal for applications which
   * cannot handle multiple entry points, as recommended by {@link VbRouter.urlPathAdapter|urlPathAdapter}.
   * <br>There are two available
   * URL adapters, this one and the {@link VbRouter.urlPathAdapter|urlPathAdapter}.<br>To change
   * the URL adapter, use the {@link VbRouter.defaults|urlAdapter} property.
   * @see VbRouter.urlPathAdapter
   * @see VbRouter.defaults
   * @example <caption>Change the default URL adapter to urlParamAdapter instead of urlPathAdapter:</caption>
   * VbRouter.defaults['urlAdapter'] = new VbRouter.urlParamAdapter();
   */
  class UrlParamAdapter extends UrlAdapter {
    /**
     * Construct an array of states where each item is an object made of a router and
     * the new state for it.
     * @param {Object} transition An object with properties describing the transition.
     * @return {!Array.<_StateChange>}
     * @throws Error when parsing of router param fails.
     */
    // eslint-disable-next-line class-methods-use-this
    parse(transition) {
      const search = _location.search;
      const params = parseQueryParam(search);
      let router = transition.rootRouter;
      let changes = [];

      log.finer('Parsing:', search);

      while (router) {
        let value = params[router._name] || router._defaultStateId;

        // Retrieve all value separated by '/'
        const segments = _getSegments(value);
        value = segments.shift();

        const stateChange = new _StateChange(router, value);

        if (value) {
          const state = stateChange.getState();

          // If this state has parameters, retrieve their values from the segments
          if (state) {
            // eslint-disable-next-line no-loop-func
            state._paramOrder.forEach(() => {
              stateChange.addParameter(segments.shift());
            });
          }

          changes.push(stateChange);
        }
        router = router._getChildRouter(value);
      }

      changes = _appendOtherChanges(changes, transition.rootRouter);

      // Retrieve the extra state from oj_Router param
      const stateStr = params[_ROUTER_PARAM];
      if (stateStr) {
        changes.forEach(_updateBookmarkableData, decodeStateParam(stateStr));
      }

      return changes;
    }

    /**
     * Given an ordered array of states, build the URL representing all
     * the states.
     * Never starts with a '/': "index.html", "book/chapter2"
     * @param {!Array.<_StateChange>} newStates
     * @return {!string} the URL representing the states
     * @throws An error if bookmarkable state is too big.
     */
    // eslint-disable-next-line class-methods-use-this
    buildUrlFromStates(newStates) {
      let canDefault = false;
      let search = '';
      const extraState = {}; // Compound object of all extra states

      // Build the new URL by walking the array of states backward in order to eliminate
      // the default state from the URL. As soon as a value is not the default, stops the removal.
      for (let ns = newStates.pop(); ns; ns = newStates.pop()) {
        if (ns.value) {
          if (canDefault || (ns.value !== ns.router._defaultStateId)) {
            // _name is already encoded
            const paramName = `&${ns.router._name}=`;
            const paramValue = ns.value;

            // Because we are traversing the array backward, insert instead of append
            search = paramName + encodeURIComponent(paramValue) + search;
            canDefault = true;
          }
        }

        // Build an object made of the extra data of each router
        if (ns.router._extra !== undefined) {
          extraState[ns.router._name] = ns.router._extra;
        }
      }

      // Replace first parameter separator from '&' to '?'
      if (search) {
        search = `?${search.substr(1)}`;
      }

      return _buildUrl({ search }, extraState);
    }
  }

  /**
   * <h3>JET Router</h3>
   * <p>The router is designed to simplify writing navigation for Single Page Applications.
   * The approach taken is to think of navigation in terms of states and transitions instead
   * of URLs and hashes. A router is always in one in a number of possible states and when
   * a UI action is taken in the application, a transition between states is executed. The
   * router is responsible to properly format the URL to reflect the current state and to
   * restore the application to the matching state when the URL changes.
   *
   * @desc
   * A Router cannot be instantiated. A static Router is created when the module is loaded and can be
   * accessed using the method {@link VbRouter.rootInstance|rootInstance}.
   * A child router can be created using the method {@link VbRouter#createChildRouter|createChildRouter}.
   * @see VbRouter.rootInstance
   * @see VbRouter#createChildRouter
   */
  class VbRouter {
    constructor(key, parentRouter, parentState) {
      /**
       * A string identifier of the router. It is required the name is unique within all the
       * sibling routers.
       * @name VbRouter#name
       * @member
       * @readonly
       * @type {!string}
       * @see VbRouter#createChildRouter
       */
      this._name = key;

      /**
       * The state of the parent router when this router is current.
       * @private
       * @type {!string | undefined}
       */
      this._parentState = parentState
        || (parentRouter ? _getShortId(parentRouter._stateId()) : undefined);

      /**
       * Used to store the bookmarkable data.
       * @private
       * @type {Object|undefined}
       */
      this._extra = undefined;

      Object.defineProperties(this, {
        /**
         * A Knockout observable for the id of the current state of the router.
         * @private
         * @readonly
         */
        _stateId: {
          value: ko.observable(),
        },

        /**
         * The parent router. Root router doesn't have one.
         * @private
         * @type {VbRouter | undefined}
         * @readonly
         */
        _parentRouter: {
          value: parentRouter,
        },

        /**
         * Array of child router.
         * @private
         * @type {Array.<VbRouter>}
         * @readonly
         */
        _childRouters: {
          value: [],
        },

        /**
         * A Knockout observable for the id of the current state.<br>
         * <code class="prettyprint">stateId()</code> returns the string id.<br>
         * <code class="prettyprint">stateId('book')</code> transitions the router to
         * the state with id 'book'.<br>
         * It is convenient to use the stateId observable when working with component
         * with 2-way binding like {@link oj.ojButtonsetOne#value|value} for
         * <code class="prettyprint">ojButtonset</code> or
         * {@link oj.ojNavigationList#selection|selection} for
         * <code class="prettyprint">ojNavigationList</code> because it does not
         * require a click on optionChange handler (See example below).
         * @name VbRouter#stateId
         * @type {function(string=): string}
         * @readonly
         *
         * @example <caption>A buttonSet using the router stateId for 2-way binding:</caption>
         * &lt;oj-buttonset-one value="{{router.stateId}}">
         *    &lt;oj-bind-for-each data="[[router.states]]">
         *      &lt;template>
         *        &lt;oj-option value="[[$current.data.id]]">
         *          &lt;span>&lt;oj-bind-text value="$current.data.label">&lt;/oj-bind-text>&lt;/span>
         *        &lt;/oj-option>
         *      &lt;/template>
         *    &lt;/oj-bind-for-each>
         * &lt;/oj-buttonset-one&gt;
         *
         */
        _stateIdComp: {
          value: ko.pureComputed({
            // Return only the significant part of the id, the part without the state parameters.
            read: () => _getShortId(this._stateId()),
            write: (value) => {
              this.go(value).then(
                null,
                (error) => {
                  throw error;
                },
              );
            },
            owner: this,
          }),
        },

        /**
         * A Knockout observable on the current {@link VbRouterState|RouterState} object.
         * @name VbRouter#currentState
         * @type {function():(VbRouterState|undefined)}
         * @readonly
         *
         * @example <caption>Hide a panel when the state of the router is not yet defined:</caption>
         *    &lt;oj-bind-if test="[[router.currentState()]]"&gt;
         *       &lt;!-- content of the panel --&gt;
         *    &lt;/oj-bind-if&gt;
         */
        _currentState: {
          value: ko.pureComputed(() => {
            const shortId = _getShortId(this._stateId());
            return ko.ignoreDependencies(this._getStateFromId, this, [shortId]);
          }),
        },

        /**
         * A Knockout observable on the value property of the current state.<br>
         * The state value property is the part of the state object that will be used in the application.
         * It is a shortcut for <code class="prettyprint">router.currentState().value;</code>
         * @name VbRouter#currentValue
         * @type {function():(string|undefined)}
         * @readonly
         *
         * @example <caption>Display the content of the current state:</caption>
         * &lt;h2 id="pageContent">
         *   &lt;oj-bind-text value="[[router.currentValue]]">&lt;/oj-bind-text>
         * &lt;/h2>
         */
        _currentValue: {
          value: ko.pureComputed(() => {
            const shortId = _getShortId(this._stateId());
            let retValue;
            const currentState = ko.ignoreDependencies(this._getStateFromId, this, [shortId]);
            if (currentState) {
              retValue = currentState.value;
            }
            return retValue;
          }),
        },
      });

      /**
       * An array of all the possible states of the router. This array is null if the router is configured
       * using a callback.
       * @name VbRouter#states
       * @type {Array.<VbRouterState>|null}
       * @private
       * @see VbRouterState
       */
      this._states = null;

      /**
       * The state id of the default state for this router. The value is set when
       * {@link VbRouter#configure|configure} is called on the router and the state isDefault property is true.
       * If it is undefined, the router will start without a state selected.
       * This property is writable and can be used to set the default state id when
       * the router is configured using a callback.
       * @name VbRouter#defaultStateId
       * @type {string|undefined}
       */
      this._defaultStateId = undefined;

      /**
       * The current navigation direction of the router, used for module animations.
       * The value will either be undefined if navigating forward, or "back" if
       * navigating backwards.
       * This is the same value available as part of {@link VbRouter#moduleConfig|moduleConfig}.
       * @name VbRouter#direction
       * @type {string|undefined}
       * @readonly
       * @since 5.0.0
       *
       */
      this._navigationType = undefined;

      /**
       * Keep track for history to managed the animation direction
       * @private
       * @type {Array}
       */
      this._navHistory = [];
    }

    /**
     * A set of Router defaults properties.<br>
     * <h6>Warning: </h6>Defaults can not be changed after the first call to {@link VbRouter.sync|sync()}
     * has been made. To re-initialize the router, you need to call {@link VbRouter#dispose|dispose()} on
     * the {@link VbRouter.rootInstance|rootInstance} first then change the defaults.
     * @property {VbRouter.urlPathAdapter|VbRouter.urlParamAdapter} [urlAdapter] an instance of the url adapter to use. If not specified, the router
     * will be using the path url adapter. Possible values are an instance of
     * {@link VbRouter.urlPathAdapter} or {@link VbRouter.urlParamAdapter}.
     * @property {string} [baseUrl] the base URL to be used for relative URL addresses. The value can be
     * absolute or relative.  If not specified, the default value is '/'.<br>
     * <b>Warning</b>: When using the {@link VbRouter.urlPathAdapter|path URL adapter} it is necessary
     * to set the base URL if your application is not using <code class="prettyprint">index.html</code>
     * or is not starting at the root folder. Using the base URL is the only way the router can retrieve
     * the part of the URL representing the state.<br>
     * @property {string} [rootInstanceName] the name used for the root router. If not defined,
     * the name is 'root'. This is used by the {@link VbRouter.urlParamAdapter|urlParamAdapter} to build
     * the URL in the form of <code class="prettyprint">/index.html?root=book</code>.
     * @export
     * @example <caption>Change the default URL adapter to the urlParamAdapter</caption>
     * VbRouter.defaults['urlAdapter'] = new VbRouter.urlParamAdapter();
     * @example <caption>Set the base URL for an application located at the root and a starting page
     * named <code class="prettyprint">index.html</code>. This is the default.</caption>
     * VbRouter.defaults['baseUrl'] = '/';
     * @example <caption>Set the base URL for an application with a page named
     * <code class="prettyprint">main.html</code> and located in the
     * <code class="prettyprint">/myApp</code> folder.</caption>
     * VbRouter.defaults['baseUrl'] = '/myApp/main.html';
     * @example <caption>Change the default root router name to 'id'</caption>
     * VbRouter.defaults['rootInstanceName'] = 'id';
     */
    static get defaults() {
      return RouterDefaults;
    }

    static get UrlParamAdapter() {
      return UrlParamAdapter;
    }

    static get UrlPathAdapter() {
      return UrlPathAdapter;
    }

    static get HistoryAdapter() {
      return HistoryAdapter;
    }

    static get VbRootRouterClass() {
      // eslint-disable-next-line no-use-before-define
      return VbRootRouter;
    }

    /**
     * The parent router if it exits.
     * Only the 'root' router does not have a parent router.
     * @name VbRouter#parent
     * @type {VbRouter|undefined}
     * @readonly
     */
    get parent() {
      return this._parentRouter;
    }

    get name() {
      return this._name;
    }

    get states() {
      return this._states;
    }

    get stateId() {
      return this._stateIdComp;
    }

    get currentState() {
      return this._currentState;
    }

    get currentValue() {
      return this._currentValue;
    }

    get direction() {
      return this._navigationType;
    }

    get defaultStateId() {
      return this._defaultStateId;
    }

    set defaultStateId(newValue) {
      this._defaultStateId = newValue;
    }

    /**
     * A {@link http://millermedeiros.github.io/js-signals/|signal} dispatched when the state transition
     * has completed either by successfully changing the state or cancelling.<br>
     * The parameter of the event handler is a boolean true when the state has changed.<br>
     * This is usefull when some post processing is needed or to test the result after a state change.
     * @name VbRouter.transitionedToState
     * @type {signals.Signal}
     * @readonly
     * @example <caption>Creates promise that resolve when the state transition is complete.</caption>
     * var promise = new Promise(function(resolve, reject) {
     *       VbRouter.transitionedToState.add(function(result) {
     *          if (result.hasChanged) {
     *             Logger.info('The state has changed');
     *          }
     *          resolve();
     *       });
     */
    static get transitionedToState() {
      return _transitionedToState;
    }

    /**
     * The static instance of {@link VbRouter} representing the unique root router.
     * This instance is created at the time the module is loaded.<br>
     * All other routers will be children of this object.<br>
     * The name of this router is 'root'. To change this name use the
     * {@link VbRouter.defaults|rootInstanceName} property.
     * @name VbRouter.rootInstance
     * @type VbRouter
     * @readonly
     * @example <caption>Retrieve the root router and configure it:</caption>
     * var router = VbRouter.rootInstance;
     * router.configure({
     *    'home':   { label: 'Home',   value: 'homeContent', isDefault: true },
     *    'book':   { label: 'Book',   value: 'bookContent' },
     *    'tables': { label: 'Tables', value: 'tablesContent' }
     * });
     */
    static get rootInstance() {
      delete VbRouter.rootInstance;
      // Make it a configurable redonly
      Object.defineProperty(VbRouter, 'rootInstance', {
        value: _rootRouter,
        enumerable: true,
        configurable: true,
      });

      return VbRouter.rootInstance;
    }

    static _getNameFromState(currentState) {
      let name;
      if (currentState) {
        name = currentState.value;
        if (!name || (typeof name !== 'string')) {
          name = currentState._id;
        }
      }
      return name;
    }

    _getRootRouter() {
      // eslint-disable-next-line no-use-before-define
      if (!this.parent || this instanceof VbRootRouter) {
        return this;
      }

      return this.parent._getRootRouter();
    }

    /**
     * Retrieve a router full name. A path of all rooter name from root.
     * @private
     * @return {!string}
     */
    _getRouterFullName() {
      if (this._parentRouter) {
        return `${this._parentRouter._getRouterFullName()}.${this._name}`;
      }

      return this._name;
    }

    /**
     * Return the child router for a specific parent state value
     * @private
     * @param {string|undefined} value
     * @return {VbRouter|undefined}
     */
    _getChildRouter(value) {
      return this._childRouters.find((sr) => !sr._parentState || sr._parentState === value);
    }

    /**
     * Traverse all the child routers in order to find a router that has the
     * state id given as an argument.
     * @private
     * @param  {!string} sId
     * @param  {string=} parentStateId
     * @return {VbRouter|undefined}
     */
    _findRouterForStateId(sId, parentStateId) {
      return this._childRouters
        .find((child) => (!child._parentState || child._parentState === parentStateId)
          && child._getStateFromId(sId));
    }

    /**
     * Traverse the tree of routers and build an array of states made of the router and an
     * undefined value.
     * The first item of the array is the root and the last is the leaf.
     * @private
     */
    _buildAllUndefinedState() {
      let states = [];

      if (this._currentState()) {
        // Push a state change with undefined value (2nd argument in constructor missing)
        states.push(new _StateChange(this));

        this._childRouters.forEach((child) => {
          states = states.concat(child._buildAllUndefinedState());
        });
      }

      return states;
    }

    /**
     * Return a child router by name.  The name is the value given to
     * {@link VbRouter#createChildRouter|createChildRouter}.
     * @param  {!string} name The name of of the child router to find
     * @return {VbRouter|undefined} The child router
     */
    getChildRouter(name) {
      let childRouter;

      if (typeof name === 'string') {
        const trimmedName = name.trim();

        if (trimmedName) {
          childRouter = this._childRouters.find((sr) => sr._name === trimmedName);
        }
      }

      return childRouter;
    }

    /**
     * Get the child router associated with the parent's current state.  See
     * {@link VbRouter#createChildRouter|createChildRouter} for details on how child routers are associated
     * with parent states.
     * @return {VbRouter|undefined} The child router for the current state, if defined.
     *
     */
    getCurrentChildRouter() {
      const sId = _getShortId(this._stateId() || this._defaultStateId);
      return this._getChildRouter(sId);
    }

    /**
     * Create a child router with the given name. A router can either have one child
     * router that handles all possible states, or one child router per state.
     * See the examples below.
     * @param {!string} name The unique name representing the router.  The name is
     * used by the function {@link VbRouter#getChildRouter|getChildRouter} to retrieve
     * the child router.
     * @param {string=} parentStateId The state Id of the parent router for determining
     * when this child router is used.
     * If not defined, the child router is created for the current state of the router.
     * @return {VbRouter} the child router
     * @throws An error if a child router exist with the same name or if the current
     * state already has a child router.
     * @export
     * @example <caption>
     * <b>Create a default child router for the parent</b>
     * In this example, the parent router is assumed to have no current state (it
     * has not yet navigated to any particular state). Since we are not specifying a
     * value for parentStateId, the newly created router will be the default child
     * router.
     * </caption>
     * // Parent router has no current state
     * router = VbRouter.rootInstance;
     * // This child router is the default child router for all parent router states
     * childRouter = router.createChildRouter('chapter');
     * @example <caption>
     * <b>Create a child router for the root's current state</b>
     * In this example, the parent has navigated to a state before the child router
     * is created.  Even though no value is given for parentStateId, the child router
     * is only used when the parent is in the particular state.
     * </caption>
     * // Parent router navigates to a given state
     * router = VbRouter.rootInstance;
     * router.go('book').then(function(result) {
     *   if (result.hasChanged) {
     *     // Child router is only used when parent router's state is 'book'
     *     // because parent now has a current state
     *     var childRouter = router.createChildRouter('chapter');
     *   }
     * });
     * @example <caption>
     * <b>Create a child router for parent state id 'book'</b>
     * In this example, the parent router hasn't yet navigated to a particular state
     * but the child specifies 'book' as its parentStateId, therefore, the child will
     * only be used when the parent is in that particular state.
     * </caption>
     * // Parent router has no current state
     * router = VbRouter.rootInstance;
     * // Child router is only used when parent router's state is 'book'
     * childRouter = router.createChildRouter('chapter', 'book');
     */
    createChildRouter(name, parentStateId) {
      const _parentStateId = parentStateId || _getShortId(this._stateId());

      const encodedName = encodeURIComponent(name.trim());
      // Make sure it doesn't already exist.
      for (let i = 0; i < this._childRouters.length; i++) {
        const sr = this._childRouters[i];
        if (sr._name === encodedName) {
          throw new Error(`Invalid router name "${encodedName}", it already exists.`);
        } else if (sr._parentState === _parentStateId) {
          throw new Error(`Cannot create more than one child router for parent state id "${sr._parentState}".`);
        }
      }

      const childRouter = new VbRouter(encodedName, this, _parentStateId);

      this._childRouters.push(childRouter);

      return childRouter;
    }

    /**
     * @private
     * @param {string} stateId The state id.
     * @return {VbRouterState | undefined} The state object.
     */
    _getStateFromId(stateId) {
      let state;
      if (this._stateFromIdCallback) {
        state = this._stateFromIdCallback(stateId);
        // If return is a config object, instantiate RouterState with it
        if (state && !(state instanceof VbRouterState)) {
          state = new VbRouterState(stateId, state, this);
        }
      } else if (stateId && this._states) {
        state = this._states.find((stateAt) => stateAt._id === stateId);
      }

      return state;
    }

    /**
     * Traverse the child router and build a chain of promise for each canExit callback.
     * @private
     * @param  {IThenable.<?>|null}  sequence
     * @return {IThenable.<?>|null}  chain of promises executing the canExit on the current states
     */
    _buildCanExitSequence(sequence) {
      const currentState = this._currentState();

      if (currentState) {
        // Traverse each child router and ask for canExit
        this._childRouters.forEach((childRouter) => {
          // eslint-disable-next-line no-param-reassign
          sequence = childRouter._buildCanExitSequence(sequence);
        });

        // A callback defined on bound viewModel has precedence.
        let canExitCallback;
        if (currentState.viewModel && currentState.viewModel.canExit) {
          canExitCallback = currentState.viewModel.canExit;
        } else {
          canExitCallback = currentState._canExit;
        }

        // eslint-disable-next-line no-param-reassign
        sequence = _chainCallback(canExitCallback, sequence);
      }

      return sequence;
    }

    /**
     * Invoke canExit callbacks in a deferred way.
     * @private
     * @return {IThenable.<?>|null} a promise returning true if all of the current state can exit.
     */
    _canExit() {
      if (isTransitionCancelled()) {
        return Promise.resolve(false);
      }

      log.finer('Start _canExit.');

      const sequence = this._buildCanExitSequence(null);
      if (sequence === null) {
        return Promise.resolve(true);
      }

      return sequence.then((result) => (result && !isTransitionCancelled()));
    }

    /**
     * Configure the states of the router. The router can be configured in two ways:
     * <ul>
     *  <li>By describing all of the possible states that can be taken by this router.</li>
     *  <li>By providing a callback returning a {@link VbRouterState|RouterState}
     *      object given a string state id.</li>
     * </ul>
     * This operation resets any previous configuration, and is chainable.<br>
     * Configuring {@link VbRouterState#parameters router state parameters} should
     * be done here.  See the example below.
     *
     * @param {!(Object.<string, {VbRouterState.ConfigOptions}> |
     *         function(string): (VbRouterState | undefined)) } option
     * Either a callback or a dictionary of states.
     * <h6>A callback:</h6>
     * <h4 id="stateFromIdCallback" class="name">
     *    stateFromIdCallback
     *    <span class="signature">(stateId)</span>
     *    <span class="type-signature">
     *       → {<a href="VbRouterState.html">VbRouterState</a>|undefined}
     *    </span>
     * </h4>
     * A function returning a {@link VbRouterState|RouterState} given a string state id.<br>
     * When using a callback, the {@link VbRouter#states|states} property will always be null since
     * states are defined on the fly.<br>See second example below.
     * <h6>A dictionary of states:</h6>
     * It is a dictionary in which the keys are state {@link VbRouterState#id|id}s and values are objects
     * defining the state.  Note that the forward slash character '/' is not allowed
     * in the state Id.  See {@link VbRouterState.ConfigOptions|ConfigOptions}.<br>See first example below.
     * <h6>Key</h6>
     * <table class="params">
     *   <thead><tr>
     *     <th>Type</th>
     *     <th class="last">Description</th>
     *   </tr></thead>
     *   <tbody>
     *     <tr>
     *       <td class="type">
     *         <span class="param-type">string</span>
     *       </td>
     *       <td class="description last">the state id.
     *       See the RouterState <a href="VbRouterState.html#id">id</a> property.</td>
     *    </tr>
     *    <tr>
     *      <td class="type">
     *        <span class="param-type">VbRouterState.ConfigOptions</span>
     *      </td>
     *      <td class="value">
     *        <span class="param-type">See {@link VbRouterState.ConfigOptions|ConfigOptions}
     *        for the options available for configuring router states</span>
     *      </td>
     *   </tbody>
     * </table>
     * @return {Router}
     * @see VbRouterState
     * @ojsignature { target:'Type',
     *   value: '{[key:string]: VbRouterState.ConfigOptions}|((id:string)=> VbRouterState|VbRouterState.ConfigOptions|undefined|null)',
     *   for: 'option'}
     * @example <caption>Add three states with id 'home', 'book' and 'tables':</caption>
     * router.configure({
     *    'home':   { label: 'Home',   value: 'homeContent', isDefault: true },
     *    'book':   { label: 'Book',   value: 'bookContent' },
     *    'tables': { label: 'Tables', value: 'tablesContent' }
     * });
     * @example <caption>Configure dynamic states via callback function:</caption>
     * router.configure(function(stateId) {
     *    var state;
     *
     *    if (stateId) {
     *       state = { value: data[stateId] };
     *    }
     *    return state;
     * });
     * @example <caption>Configuring {@link VbRouterState#parameters state parameters}:</caption>
     * router.configure({
     *    'home':   { label: 'Home',   value: 'homeContent', isDefault: true },
     *    'book/{chapter}/{paragraph}':   { label: 'Book',   value: 'bookContent' },
     * });
     */
    configure(option) {
      this._stateId(undefined);
      delete this._defaultStateId;
      // StateId are changing so erase history.
      this._navigationType = undefined;
      this._navHistory = [];

      if (typeof option === 'function') {
        this._states = null;
        // Override prototype
        this._stateFromIdCallback = option;
      } else {
        this._states = [];
        this._stateFromIdCallback = undefined;

        Object.keys(option).forEach((key) => {
          const rsOptions = option[key];
          this._states.push(new VbRouterState(key, rsOptions, this));
          // Set the defaultStateId of the router from the isDefault property
          if ((typeof (rsOptions.isDefault) === 'boolean') && rsOptions.isDefault) {
            this._defaultStateId = _getShortId(key);
          }
        }, this);
      }

      return this;
    }

    /**
     * Return the {@link VbRouterState} object which state id matches one of the possible states of the router.
     * @param {string} stateId - the id of the requested {@link VbRouterState} object.
     * @return {VbRouterState|undefined} the state object matching the id.
     * @example <caption>Retrieve the RouterState for id 'home':</caption>
     * var homeState = router.getState('home');
     * var homeStateValue = homeState.value;
     */
    getState(stateId) {
      return this._getStateFromId(stateId);
    }

    /**
     * Go is used to transition to a new state using a path made of state ids separated by a slash.  The
     * path can be absolute or relative.<br>
     * <br>
     * Example of valid path:
     * <ul>
     *   <li><code class="prettyprint">router.go('home')</code>: transition router
     *    to state id 'home'</li>
     *   <li><code class="prettyprint">router.go('/book/chapt2')</code>: transition
     *    the root instance to state id 'book' and the child router to state id
     *    'chapt2'</li>
     *   <li><code class="prettyprint">router.go('chapt2/edit')</code>: transition
     *   router to state id 'chapt2' and child router to state id 'edit'</li>
     *   <li><code class="prettyprint">router.go(['chapt2','edit'])</code>: equivalent
     *   to the previous transition, but using an array of path strings in place of
     *   forward slashes.
     * </ul>
     * <br>
     * If the stateIdPath argument is <code class="prettyprint">undefined</code> or an empty string, go
     * transition to the default state of the router.<br>
     * A {@link VbRouter.transitionedToState|transitionedToState} signal is
     * dispatched when the state transition has completed.
     * @param {(string|Array.<string>)=} stateIdPath A path of ids representing the state to
     * which to transition, separated by forward slashes (/).  This can also be an Array
     * of strings, each segment representing individual states.  An array is typically used
     * if the forward slash should be part of the state Id and needs to be distinguished
     * from the path separators.
     * @param {Object=} options - an object with additional information on how to execute the transition.
     * @param {string} options.historyUpdate Specify how the transition should act on the browser
     * history. If this property is not specified, a new URL is added to the history.<br>
     * <b><i>Supported Values:</i></b><br>
     * <code class="prettyprint">'skip'</code>: does not update the history with the new URL<br>
     * <code class="prettyprint">'replace'</code>: modifies the current history with the new URL
     * @return {!Promise.<{hasChanged: boolean}>} A Promise that resolves when the
     * router is done with the state transition.<br>
     * When the promise is fullfilled, the parameter value is an object with the property
     * <code class="prettyprint">hasChanged</code>.<br>
     * The value of <code class="prettyprint">hasChanged</code> is:
     * <ul>
     *   <li>true: If the router state changed.</li>
     * </ul>
     * When the Promise is rejected, the parameter value is:
     * <ul>
     *   <li>An Error object stipulating the reason for the rejection during the
     *   resolution. Possible errors are:
     *   <ul>
     *     <li>If stateIdPath is defined but is not of type string.</li>
     *     <li>If stateIdPath is undefined but the router has no default state.</li>
     *     <li>If a state id part of the path cannot be found in a router.</li>
     *   </ul>
     *   </li>
     * </ul>
     * @example <caption>Transition a router to the state id 'home':</caption>
     * router.go('home');
     * @example <caption>Transition a router to its default state and handle errors:</caption>
     * router.go().then(
     *    function(result) {
     *       if (result.hasChanged) {
     *          Logger.info('Router transitioned to default state.');
     *       }
     *       else {
     *          Logger.info('No transition, Router was already in default state.');
     *       }
     *    },
     *    function(error) {
     *       Logger.error('Transition to default state failed: ' + error.message);
     *    }
     * );
     * @example <caption>Transition a router to state id 'stepB' without updating the URL:</caption>
     * wizardRouter.go('stepB', { historyUpdate: 'skip' });
     */
    go(stateIdPath, options = {}) {
      _initialize();

      if (Array.isArray(stateIdPath)) {
        // eslint-disable-next-line no-param-reassign
        stateIdPath = stateIdPath.map(_encodeSlash).join('/');
      }

      // eslint-disable-next-line no-use-before-define
      return _queueTransition({
        rootRouter: this._getRootRouter(),
        router: this,
        path: stateIdPath,
        origin: 'go',
        historyUpdate: options.historyUpdate,
      });
    }

    /**
     * Retrieves the absolute path to the current state. If one of the parent router current state is
     * not defined, the path is meaningless so returns undefined.
     * @private
     * @return {string} path
     */
    _getCurrentPath() {
      if (!this._parentRouter) {
        return '/';
      }

      const path = this._parentRouter._getCurrentPath();
      const sId = this._stateId();
      if (!sId) {
        throw new Error('Invalid router hierarchy. The parent router does not have a current state.');
      }

      return `${path}${sId}/`;
    }

    /**
     * Internal go used by _executeTransition
     * @private
     * @param  {Object} transition An object with properties describing the transition
     * @return {any} A Promise that resolves when the routing is done
     */
    _go(transition) {
      return Promise.resolve().then(() => {
        let newStates;
        let useDefault = true;
        let stateIdPath = transition.path;
        let replace = false;
        let skip = false;

        switch (transition.historyUpdate) {
          case 'skip':
            skip = true;
            break;
          case 'replace':
            replace = true;
            break;
          default:
            break;
        }

        if (stateIdPath) {
          if (typeof stateIdPath === 'string') {
            useDefault = false;
          } else {
            throw new Error('Invalid object type for state id.');
          }
        }

        if (useDefault) {
          stateIdPath = this._defaultStateId;
          if (!stateIdPath) {
            // No default defined, so nowhere to go.
            if (log.isFiner) {
              log.finer('Undefined state id with no default id on router', this._getRouterFullName());
            }
            return _NO_CHANGE_OBJECT;
          }
        }

        let path;
        // Absolute or relative?
        if (stateIdPath[0] === '/') {
          path = stateIdPath;
        } else {
          path = `${this._getCurrentPath()}${stateIdPath}`;
        }

        log.finer('Destination path:', path);

        newStates = _buildStateFromPath(this, path);
        newStates = _appendOtherChanges(newStates, transition.rootRouter);

        // It is important that we do not call canEnter on state that we not going to enter so
        // only keep changes where the value doesn't match the current router state.
        // reducedState is an array of object with 2 properties, value and router.
        const reducedState = _filterNewState(newStates);

        // Only transition if replace is true or if the new state is different.
        // When replace is true, it is possible the states are the same (by example when going to the
        // default state of a child router) but the transition still need to be executed.
        if (replace || reducedState.length > 0) {
          log.finer('Deferred mode or new state is different.');
          return this._canExit().then((canExit) => {
            if (canExit) {
              // Only calls canEnter callback on state that are changing
              return _canEnter(reducedState).then(_updateAll).then((params) => {
                if (params[_HAS_CHANGED]) {
                  if (skip) {
                    log.finer('Skip history update.');
                  } else {
                    transition.rootRouter.updateUrl(newStates, replace);
                  }
                }
                return params;
              });
            }
            return _NO_CHANGE_OBJECT;
          });
        }

        return _NO_CHANGE_OBJECT;
      });
    }

    /**
     * Store additional data for this router that will be added in a compressed form to the URL
     * so it can be bookmarked. When calling this method, the URL is immediately modified.
     * @param {!Object} data the data to store with this state.
     * @throws An error if the bookmarkable state is too big.
     * @return {undefined}
     * @ojsignature [{ target: "Type", for: "data", value: "{[key:string]:any}" }]
     * @example <caption>Store a color in the URL:</caption>
     * try {
     *    var color = '#99CCFF';
     *    router.store(color);
     *    $('#chapter').css('background', color);
     * }
     * catch (error) {
     *    Logger.error('Error while storing data: ' + error.message);
     * }
     */
    store(data) {
      this._extra = data;

      const extraState = {};
      let router = this;

      // Walk the parent routers
      while (router) {
        if (router._extra !== undefined) {
          extraState[router._name] = router._extra;
        }
        router = router._parentRouter;
      }

      // and the children routers
      router = this;
      let nextLevel;
      while (router) {
        for (let i = 0; i < router._childRouters.length; i++) {
          const sr = router._childRouters[i];
          const shortId = _getShortId(router._stateId());
          if (shortId && shortId === sr._parentState) {
            if (sr._extra !== undefined) {
              extraState[sr._name] = sr._extra;
            }
            nextLevel = sr;
            break;
          }
        }
        router = nextLevel;
        nextLevel = undefined;
      }

      window.history.replaceState(null, '', _buildUrl({}, extraState));
    }

    /**
     * Retrieve the additional data stored in the URL.
     * @return {any} the content stored in the URL
     * @ojsignature [{ target: "Type", for: "returns", value: "{[key:string]:any}" }]
     * @example <caption>Retrieve the value of the background color stored in the URL:</caption>
     *  VbRouter.sync().then(
     *     function() {
     *        var color = viewModel.router.retrieve();
     *        if (color) {
     *           $('#chapter').css('background', color);
     *        }
     *     },
     *     function(error) {
     *        Logger.error('Error during sync: ' + error.message);
     *     }
     *  );
     */
    retrieve() {
      return this._extra;
    }

    /**
     * Dispose the router.<br>
     * Erase all states of this router and its children.
     * Remove itself from parent router child list.<br>
     * When this method is invoked on the {@link VbRouter.rootInstance|rootInstance}, it
     * also remove internal event listeners and re-initialize the
     * {@link VbRouter.defaults|defaults}.
     * @return {undefined}
     */
    dispose() {
      // Depth first
      while (this._childRouters.length > 0) {
        this._childRouters[0].dispose();
      }

      // If this is the root, clean up statics
      if (this === _rootRouter) {
        _baseUrlProp = '/'; // Restore the default value
        _rootRouter.urlAdapter = null;
        this._name = _DEFAULT_ROOT_NAME;
        // Restore title
        window.document.title = _originalTitle;

        window.removeEventListener(_POPSTATE, popStateEventListener, false);
        _transitionedToState.removeAll();
        _initialized = false;
      } else if (this._parentRouter) {
        // Remove itself from parent children array.
        const parentChildren = this._parentRouter._childRouters;
        for (let i = 0; i < parentChildren.length; i++) {
          if (parentChildren[i]._name === this._name) {
            parentChildren.splice(i, 1);
            break;
          }
        }

        delete this._parentState;
      }

      this._navigationType = undefined;
      this._navHistory = [];
      this._states = null;
      delete this._stateFromIdCallback;
      delete this._defaultStateId;
      delete this._extra;
    }

    /**
     * Synchronise the router with the current URL. The process parse the URL and
     * <ol>
     *   <li>transition the router to a new state matching the URL.</li>
     *   <li>initialize the bookmarkable storage.</li>
     *   <li>dispatch a {@link VbRouter.transitionedToState|transitionedToState} signal.</li>
     * </ol>
     * It has to be called after a router is configured, to synchronise the URL with the
     * router state.<br>
     * If a default state is defined, the router will transition to it, otherwise no transition will
     * occur and the router will be in an undefined state.<br>
     * Because the process of transitioning between two states invokes callbacks (canExit, canEnter)
     * that are promises, this function also returns a promise.
     * @return {!Promise.<{hasChanged: boolean}>} A Promise that resolves when the router is done with
     * the state transition.<br>
     * When the Promise is fullfilled, the parameter value is an object with the property
     * <code class="prettyprint">hasChanged</code>.<br>
     * The value of <code class="prettyprint">hasChanged</code> is:
     * <ul>
     *   <li>true: If the router state changed.</li>
     * </ul>
     * When the Promise is rejected, the parameter value is:
     * <ul>
     *   <li>An Error object stipulating the reason for the rejection when an error
     * occurred during the resolution.</li>
     * </ul>
     * @export
     * @example <caption>Start the root instance</caption>
     * var router = VbRouter.rootInstance;
     * // Add three states to the router with id 'home', 'book' and 'tables
     * router.configure({
     *    'home':   { label: 'Home',   value: 'homeContent', isDefault: true },
     *    'book':   { label: 'Book',   value: 'bookContent' },
     *    'tables': { label: 'Tables', value: 'tablesContent' }
     * });
     *
     * var viewModel = {
     *    router: router
     * };
     *
     * VbRouter.sync().then(
     *    function() {
     *       ko.applyBindings(viewModel);
     *    },
     *    function(error) {
     *       Logger.error('Error when starting the router: ' + error.message);
     *    }
     * );
     * @example <caption>Synchronise a newly created child Router and retrieve the bookmarkable state</caption>
     *  VbRouter.sync().then(
     *     function() {
     *        var color = viewModel.router.retrieve();
     *        if (color) {
     *           $('#chapter').css('background', color);
     *        }
     *     },
     *     function(error) {
     *        Logger.error('Error during sync: ' + error.message);
     *     }
     *  );
     *
     */
    static sync() {
      // eslint-disable-next-line no-use-before-define
      const rootRouter = VbRootRouter.rootInstance;
      // Case of the switcher state of a deleted switcher
      if (!rootRouter) {
        return;
      }

      const transition = { rootRouter, router: rootRouter, origin: 'sync' };

      _initialize();

      log.finer('Entering sync with URL:', _location.href);

      if (_deferredPath) {
        transition.path = _deferredPath;
        transition.deferredHandling = true;
        transition.historyUpdate = 'replace';
        _deferredPath = undefined;
        // eslint-disable-next-line no-use-before-define
        return _queueTransition(transition);
      }

      if (_updating) {
        log.finer('Sync called while updating, waiting for updates to end.');
        // Returms a promise that resolve as soon as the current transition is complete.
        return new Promise((resolve) => {
          _transitionedToState.addOnce((result) => {
            log.finer('Sync updates done.');
            resolve(result);
          });
        });
      }

      // eslint-disable-next-line no-use-before-define
      return _queueTransition(transition);
    }
  }

  class VbRootRouter extends VbRouter {
    // Default adapter is the UrlPathAdapter
    constructor(key = _DEFAULT_ROOT_NAME, urlAdapter = new VbRouter.UrlPathAdapter()) {
      super(key);

      /**
       * Hold the url adapter to be used.
       * @private
       * @type {VbRouter.urlPathAdapter|VbRouter.urlParamAdapter}
       */
      this.urlAdapter = urlAdapter;

      if (!VbRootRouter.allRootRouters) {
        VbRootRouter.allRootRouters = {};
      }

      if (VbRootRouter.allRootRouters[key]) {
        throw new Error(`Root router ${key} already exist.`);
      }

      VbRootRouter.allRootRouters[key] = this;

      this.currentNavPath = null;
    }

    /**
     * Sets the current navigation path for this router.
     * It is set by the application when handling the navigated event.
     * This is used when navigating to recognize when navigating to the same page
     *
     * @param {String}  navPath  The navigation path
     */
    setCurrentNavPath(navPath) {
      this.currentNavPath = navPath;
    }

    /**
     * Gets the current navigation path.
     * This is used when navigating to recognize when navigating to the same page
     *
     * @return {String}  The current navigation path.
     */
    getCurrentNavPath() {
      return this.currentNavPath;
    }

    handlePopState() {
      const sId = _getShortId(this._stateId());
      let subRouter = null;

      log.finer('Handling popState event with URL:', _location.href);

      // First retrieve the sub-router associated with the current state, if there is one.
      if (sId) {
        subRouter = this._childRouters.find((sr) => sId === sr.parent.stateId());
      }

      // eslint-disable-next-line no-use-before-define
      _queueTransition({
        rootRouter: this,
        router: subRouter,
        origin: 'popState',
      });
    }

    updateUrl(newStates, replace) {
      const url = this.urlAdapter.buildUrlFromStates(newStates);
      log.finer(replace ? 'Replacing' : 'Pushing', 'URL to', url);
      window.history[replace ? 'replaceState' : 'pushState'](null, '', url);
    }

    dispose() {
      super.dispose();
      delete VbRootRouter.allRootRouters[this.name];
      this.currentNavPath = null;
    }
  }

  /**
   * Create the instance of the root router.
   */
  _rootRouter = new VbRootRouter();

  /**
   * Dispatch the transitionedToState signal
   * @private
   * @param {Object} param
   * @param {boolean} param.haschanged
   * @param {VbRouter=} param.router
   * @param {VbRouterState=} param.oldState
   * @param {VbRouterState=} param.newState
   */
  function dispatchTransitionedToState(param) {
    _transitionedToState.dispatch(param);
  }

  /**
   * Log the action and transaction as Logger.info.
   * @param       {string} action     The action performed
   * @param       {Object} transition The router transition.  The transition object
   * contains the following properties:
   * - {string} path The path to where the transition is occurring
   * - {string} origin The origin of the transition.  Will be one of, "sync", "go",
   *   "popState"
   * - {VbRouter} router The instance of the router performing the transition
   * - {string} historyUpdate A string indicating how the router should update the
   *   browser history.  Possible values are, "skip" or "replace"
   *   updated
   * @private
   */
  function _logTransition(action, transition) {
    if (log.isFiner) {
      const path = transition.path ? `path=${transition.path}` : '';
      const deferString = transition.deferredHandling ? 'deferredHandling=true' : '';
      const router = transition.router ? transition.router._getRouterFullName() : 'null';
      log.finer('>>', action, 'origin=', transition.origin, 'router=', router, path, deferString);
    }
  }

  /**
   * Execute a transition. There are 3 types of transitions depending if they are
   * called from go, sync or handlePopState.
   * @private
   * @param  {Object} transition An object with properties describing the transition.
   * @return A Promise that resolves when the router is done with the state transition.
   */
  function _executeTransition(transition) {
    _logTransition('Executing', transition);

    if (!transition.deferredHandling) {
      // if the transition originate from a sync call, don't call canExit
      if (transition.origin === 'sync') {
        return parseAndUpdate(transition);
      }

      if (transition.origin === 'popState') {
        const router = transition.router;
        if (!router) {
          return Promise.resolve(true);
        }

        return router._canExit()
          .then((canExit) => (canExit ? parseAndUpdate(transition) : _NO_CHANGE_OBJECT));
      }
    }
    return transition.router._go(transition);
  }

  /**
   * Executes first transition on the queue then unqueue then recurse.
   * @return {Promise}
   * @private
   */
  function _resolveTransition() {
    const transition = _transitionQueue[0];
    let promise;

    _logTransition('Resolving', transition);

    if (transition.cancel) {
      _logTransition('Cancelled', transition);
      promise = Promise.resolve(_NO_CHANGE_OBJECT);
    } else {
      promise = _executeTransition(transition);
    }

    return promise.then((params) => {
      const done = _transitionQueue.shift();
      _logTransition('Done with', done);
      if (params[_HAS_CHANGED] === true) {
        // Build the window title that will appear in the browser history
        const titleInfo = _buildTitle(_rootRouter);
        let title;

        if (titleInfo.title !== '') {
          title = titleInfo.title;
        } else if (_originalTitle && _originalTitle.length > 0) {
          title = _originalTitle;
          if (titleInfo.segment !== '') {
            title += _TITLE_SEP + titleInfo.segment;
          }
        } else {
          title = titleInfo.segment;
        }

        if (title !== window.document.title) {
          window.document.title = title;
        }
      }
      dispatchTransitionedToState(params);
      return params;
    }).catch((error) => {
      _transitionQueue = [];
      log.error('Error when executing transition:', error);
      dispatchTransitionedToState(_NO_CHANGE_OBJECT);
      throw error;
    });
  }

  /**
   * Queue a transition. It will execute as soon as previous transitions in the
   * queue are done.
   * @private
   * @param  {Object} transition An object with properties describing the transition
   * @return A Promise that resolves when the router is done with the given state transition.
   */
  function _queueTransition(transition) {
    _logTransition('Queuing', transition);

    // var path = transition.path;
    // var bc = oj.Context.getPageContext().getBusyContext();
    // Disabling the state due to  - OJ.TESTS.ROUTER.SAMPLEOJMODULETEST FAILS
    // var removeBusyState = bc.addBusyState({description:'router transitioning to new state "'+path+'"'});

    // Push new transition at the end. Current transition is always at index 0
    const length = _transitionQueue.push(transition);

    // Simple case when the transition is the only one in the queue.
    if (length === 1) {
      _queuePromise = _resolveTransition();
    } else {
      // Cancel transition in queue and chain it
      const lastTransition = _transitionQueue[length - 2];
      // Don't cancel transitions from popstate event or for deferred path
      if (!lastTransition.deferredHandling) {
        _logTransition('Cancelling', lastTransition);
        lastTransition.cancel = true;
      }
      _queuePromise = _queuePromise.then(_resolveTransition);
    }

    return _queuePromise;
    // .then(function(result) {
    //     removeBusyState();
    //     return result;
    // }, function(error) {
    //     removeBusyState();
    //     return _queuePromise;
    // });
  }

  /**
   * The RouterState module.
   */
  const stateParamExp = /^{(\w+)}$/;

  class VbRouterState {
    /**
     * Constructs a new instance.
     *
     * @param {String}  id
     * @param {Object}  [options={}]
     * @param {VbRouter} router
     */
    constructor(id, options = {}, router) {
      if (!id) {
        throw new Error('An id is required when creating a RouterState object.');
      }
      if (!router) {
        throw new Error('A Router is required when creating a RouterState object.');
      }

      // The id is in the form /aaa/{p1}/{p2}
      const path = id.trim().split('/');
      // We cannot have duplicate because the format of the object parameter
      // doesn't allow it.
      this._id = path.shift();

      this._parameters = {};

      this._paramOrder = [];

      path.forEach((pathItem, i) => {
        /*
          * Match pattern "{token}"
          */
        const match = pathItem.match(stateParamExp);
        if (match) {
          const token = match[1];
          this._parameters[token] = null;
          this._paramOrder[i] = token;
        }
      }, this);

      this._canEnter = options.canEnter;

      this._enter = options.enter;

      this._canExit = options.canExit;

      this._exit = options.exit;

      this._value = options.value;

      this._label = options.label;

      this._title = options.title;

      this._router = router;

      this.viewModel = undefined;

      this._stateFromIdCallback = null; // Initialized in configure
    }

    go() {
      return this._router.go(this._id);
    }

    isCurrent() {
      return this._router._stateId() === this._id;
    }

    get id() {
      return this._id;
    }

    get value() {
      return this._value;
    }

    get label() {
      return this._label;
    }

    set label(newValue) {
      this._label = newValue;
    }

    get title() {
      return this._title;
    }

    set title(newValue) {
      this._title = newValue;
    }

    get parameters() {
      return this._parameters;
    }
  }

  return VbRouter;
});


'use strict';

define('vb/private/stateManagement/fragment',[
  'knockout',
  'vb/private/stateManagement/container',
  'vb/helpers/mixin',
  'vb/private/stateManagement/fragmentHolderBaseMixin',
  'vb/private/stateManagement/eventBehaviorMixin',
  'vb/private/stateManagement/context/fragmentContext',
  'vb/private/stateManagement/fragmentExtension',
  'vb/private/stateManagement/fragmentModuleViewModel',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/monitoring/loadMonitorOptions',
  'vb/private/monitoring/activateMonitorOptions',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/stateManagement/stateMonitor',
  'vb/private/events/eventMonitorOptions',
  'vb/private/translations/bundleUtils',
], (ko, Container, Mixin, FragmentHolderBaseMixin, EventBehaviorMixin, FragmentContext, FragmentExtension,
  FragmentModuleViewModel, Log, LogConfig, LoadMonitorOptions, ActivateMonitorOptions, Utils, Constants, StateMonitor,
  EventMonitorOptions, BundleUtils) => {
  const logger = Log.getLogger('/vb/stateManagement/fragment', [
    // Register a custom logger
    {
      name: 'coralInfo',
      severity: 'info',
      style: 'coral',
    },
    {
      name: 'beforeHandleEvent',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.containerStart,
    },
    {
      name: 'afterHandleEvent',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.containerEnd,
    },
  ]);

  const RESERVED_DEFAULT_SLOT_NAME = '--vb-reserved-slot-default--';

  /**
   * Fragment class: A VB model for representing a fragment instance that is generally associated to a page or a
   * fragment. A page / fragment can contain one or more fragments.
   *
   * One instance of a Fragment class is associated to each occurrence of a <oj-vb-fragment> component in a page or a
   * fragment.
   * A single instance of Fragment is responsible to show one fragment view/viewModel identified by a name and
   * located under /fragments folder.
   *
   * Example author creates html|js|json triad under '/fragments/charlie'. 'charlie' is the name of the fragment.
   *
   * A page / fragment can include the same fragment multiple times. Example 'cartoon' fragment -
   * <oj-vb-fragment id='peanutz' name='cartoon'></oj-vb-fragment>
   * <oj-vb-fragment id='calvin' name='cartoon'></oj-vb-fragment>
   *
   * 2 instances are created, each identified by its 'id'; both fragment instances load the same view/viewModel -
   * charlie. The params passed to each usually will differ.
   * @class Fragment
   */
  class Fragment extends Mixin(Container).with(FragmentHolderBaseMixin, EventBehaviorMixin) {
    /**
     * Creates a Fragment instance
     * @param {string} id unique identifier to locate the fragment. Caller must provide a user friendly value but if not
     * provided a unique key will be created.
     * @param {Container} parent container.
     * @param {string} className name of the class
     * @param {string} name (required) name of the fragment to load. this might come in later. See loadFragment
     * @param {Object|*} params (optional) input parameters provided by caller to this fragment. This will be used to
     * initialize variables in the fragment
     * @constructor
     */
    constructor(id, parent, className = 'Fragment', name, params, templates) {
      super(id, parent, className);
      this.log = logger;

      this.fragmentName = name;
      this.inputParams = params;
      this.templates = templates;
      this.viewTemplateByLocator = {};
      this.viewTemplateDataAliasByLocator = {};

      // fragments loaded from app root
      const path = ''; // resourcePath
      Object.defineProperties(this, {
        path: {
          get() {
            return `${path}fragments/${this.resourceName}/`;
          },
          enumerable: true,
        },
      });

      /**
       * moduleConfig observable
       * @type { import("knockout").observable }
       */
      this.moduleConfig = ko.observable(Constants.blankModuleConfig);

      // expressionContext is created early to allow the vb-fragment to be initialized with it. The vb-fragment
      // component initiates loading of the actual fragment artifacts.
      // TODO: container of the fragment might generally creates the context object in its loadMetadata. It needs to be
      //  overridden to stop duplicate creation.
      this.expressionContext = new FragmentContext(this);

      /**
       * whether we have already started loading a fragment and in the middle of a beforeEnter event
       * @type {boolean}
       */
      this.inBeforeEvent = false;
      this.fragmentLifecycleState = undefined;

      // Promises
      this.loadAndStartPromise = null; // created when a fragment is starting to load
      this.loadFragmentPromise = null; // created when fragment artifacts are loaded
      this.initializePromise = null; // created when a fragment is initialized and activated; its state primarily
    }

    defineInfoBuiltinVariable() {
      return {
        id: this.id,
        title: this.definition.title,
        description: this.definition.description,
      };
    }

    /**
     * returns the FragmentContext constructor used to create the '$fragment' expression context
     * @return { FragmentContext }
     */
    static get ContextType() {
      return FragmentContext;
    }

    static get extensionClass() {
      return FragmentExtension;
    }

    /**
     * A Fragment instance can also be created by another fragment
     * @return { Fragment }
     */
    static get FragmentClass() {
      return Fragment;
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor.
     * @type {string}
     */
    get fullName() {
      return `${this.resourceName}-fragment`;
    }

    /**
     * The resourceName can be different from the name (which is set to the 'id' by container. When it loads a
     * specific resource it must use this property as the actual name of the resource that this fragment represents.
     * Example: fragment id is unique foo1/foo2 etc. Each instance of fragment loads the same resource
     * 'foo-fragment.*', So resourceName is 'foo'. This rule may not apply to other containers.
     * @return {*}
     */
    get resourceName() {
      return this.fragmentName;
    }

    /**
     * returns false for event that is not declared
     * @param eventModel
     * @return {boolean}
     */
    canFireEvent(eventModel) {
      if (eventModel && !eventModel.isDeclared) {
        return false;
      }
      return super.canFireEvent(eventModel);
    }

    /**
     * Build a map of all possible input parameters to pass to (beforeEnter) event
     * @return {Object} a map of parameters
     */
    buildAllParameters() {
      // Add variables input parameters
      const parameters = {};
      this.buildParameters('variables', parameters);
      // Add constants input parameters
      // If an input parameter is already defined as a variable, the constant is used.
      this.buildParameters('constants', parameters);
      return parameters;
    }

    /**
     * Traverse a set of definitions (variables or constants) and build new parameters
     * If the input parameter is already in the map, overwrite the existing definition
     * @param  {String} defName either 'variables' or 'constants'
     * @param  {Object} allParameters the map of all the parameters
     */
    buildParameters(defName, allParameters) {
      const parameters = allParameters;
      const defs = this.definition[defName];
      Object.keys(defs).forEach((name) => {
        if (parameters[name]) {
          this.log.warn(`Input parameter ${name} is already defined. Using the definition in ${defName}.`);
        }
        const def = defs[name];
        const inputParameterValue = this.getInputParameterValue(name, def);
        parameters[name] = inputParameterValue;
        this.log.info(`Input parameter ${name}[input='${def.input}'] value:`, inputParameterValue);
      });
    }

    /**
     * Creates a moduleConfig object similar to what oj-module expects.
     * @return {{view: (Promise<string|string[]>), viewModel: Promise<Object>, params: (string)}}
     */
    createModuleConfig() {
      return {
        // initialize the variables before returning the viewModel
        viewModel: this.getViewModel(),
        // params is only used to know which fragment is represented
        params: this.fullPath,
        view: this.getView(),
      };
    }

    /**
     * Returns true if access to fragment is allowed from current context
     * @param descriptor fragment descriptor. subclasses can override to do specific checks
     * @return {boolean}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    checkFragmentAccess(descriptor) {
      return true;
    }

    /**
     * Called after the fragment CCA view is first inserted into the DOM and then each time the composite is
     * reconnected to the DOM after being disconnected.
     */
    componentConnected() {
      this.fragmentLifecycleState = Constants.FragmentState.COMPONENT_CONNECTED;
    }

    /**
     * Fragment CCA disconnected callback invoked when this composite component is disconnected from the DOM. This
     * method might be called multiple times.
     * @param fragmentNodes the children of oj-vb-fragment are provided here. See fragmentViewModel#disconnected
     * https://jet.us.oracle.com/11.0.0/jsdocs/Composite.html#ViewModel
     */
    // eslint-disable-next-line class-methods-use-this
    componentDisconnected(fragmentNodes) {
      // stop observing ojModule node.
      if (fragmentNodes && fragmentNodes.length > 0) {
        const ojModuleNode = fragmentNodes[0];
        if (ojModuleNode && ojModuleNode.localName === 'oj-module') {
          const rootIS = Utils.vbModuleObserver.getIntersectionObserver();
          if (rootIS) {
            rootIS.unobserve(ojModuleNode);
          }
        }
      }

      this.fragmentLifecycleState = Constants.FragmentState.COMPONENT_DISCONNECTED;
    }

    /**
     * Handles the vbExit event as a special behavior where the fragment walks all its nested fragments to fire exit
     * event.
     * @param eventName
     * @param eventPayload
     * @param eventBehavior
     * @param previousResult
     * @return {Promise<unknown>|Promise}
     */
    invokeEvent(eventName, eventPayload, eventBehavior, previousResult) {
      const promises = [];

      if (eventName === Constants.EXIT_EVENT) {
        Object.keys(this.fragments).forEach((fid) => {
          const frag = this.fragments[fid];
          // TODO: should events be fired on fragments that have been disconnected (where the fragment DOM is hidden
          // or not active? At the moment we fire exit for all fragments, connected or otherwise.
          promises.push(frag.invokeEvent(eventName));
        });

        // wait for child fragment vbexit promises to be fufilled before invoking same event for current fragment
        return Promise.all(promises).then(() => super.invokeEvent(eventName));
      }
      return super.invokeEvent(eventName, eventPayload, eventBehavior, previousResult);
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getFragmentDescriptor';
    }

    dispose() {
      // Mutates ojModule in order to release inner ko bindings
      // this.moduleConfig(Constants.blankModuleConfig);

      this.parent.deleteFragment(this.id);

      delete this.definition;
      this.lifecycleState = Constants.ContainerState.DISPOSED;

      this.initializePromise = null;

      this.viewModelPromise = null;
      this.loadAndStartPromise = null;

      // should dispose the scope from the store.
      super.dispose();
    }

    /**
     * Enter a fragment, i.e, fragment needs to be loaded, initialized and activated. This is almost always happens
     * under the following scenarios -
     * (1) when a vb-fragment CCA starts to render
     * (2) when a vb-fragment CCA disconnects and reconnects
     * (3) (unsupported) when a vb-fragment 'name' property changes, requiring a new fragment to be loaded
     *
     * @return {import("knockout").observable} a moduleConfig object is created whose viewModel and view properties are
     * Promises that are pending until they are fully resolved. The moduleConfig is set on the observable
     * this.moduleConfig, which is returned from getModuleConfig() method.
     */
    enter() {
      // we kick off the loading, init and activation steps for the fragment
      this.getInitializePromise();

      const newModuleConfig = this.createModuleConfig();
      this.moduleConfig(newModuleConfig);
      return this.moduleConfig;
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getFragmentFunctions';
    }

    /**
     * The callback used to dispatch event to fragment CCA
     * @return {*}
     */
    getEventDispatcher() {
      return this.availableContexts[Constants.ContextNameInternal.DISPATCH_EVENT];
    }

    /**
     * Returns a Promise that resolves when fragment has been initialized and activated. If the fragment enter has
     * been vetoed by beforeEnter activation of variables is skipped and this method returns undefined
     * @return {*|Promise<this|undefined>} resolves when fragment has been fully initialized and activated. returns
     * undefined if
     * the viewModel cannot be initialized; else returns this
     */
    getInitializePromise() {
      // we are here because we are asked to load a new fragment when we are in the middle of loading a different
      // fragment.
      this.initializePromise = this.initializePromise || this.loadAndStart()
        .then((result) => {
          if (!result) {
            // loadAndStart returns undefined if the fragment cannot be entered!
            // TODO: what should we do here when beforeEnter is canceled?
            this.log.info('vbBeforeEnter event listener has canceled entering the fragment', this.resourceName,
              'with id', this.id);
            return undefined;
          }
          return this.initializeScopeAndContextVariables().then(() => this);
        });

      return this.initializePromise;
    }

    /**
     * Returns the value for the variable using input params from parent container.
     * @param {String} variableName
     * @param {Object} variableDef
     * @return {*}
     */
    getInputParameterFromCallerValue(variableName, variableDef) {
      // first load the value from runtimeEnvironment which takes precedence over everything else
      // NOTE: This will be used by the DT to pass parameters different fragments
      let fromCallerValue = super.getInputParameterFromCallerValue(variableName);

      if (fromCallerValue === undefined) {
        const fip = this.inputParams;
        fromCallerValue = fip && fip[variableName] && fip[variableName].value;
        if (variableDef.required && fromCallerValue === undefined) {
          if (Utils.isInstanceType(variableDef.type)) {
            this.log.error('A required value was not provided for fragment variable', variableName, '! A value must',
              ' be provided for variables that require a reference.');
          } else {
            this.log.error('A required value was not provided for fragment variable', variableName, '. A value will be',
              'attempted to be initialized from other sources such as History, persistence store, configuration.');
          }
        }
      }
      return fromCallerValue;
    }

    /**
     * Used by event processing. For Fragment containers, we start (and end) with the Fragment itself,
     * unless it is a "dynamicComponent" event, which uses a completely different propagation implementation.
     * (For 'dynamicComponent' behavior, FireCustomEventAction delegates to the JET component.)
     * @see FireCustomEventAction
     *
     * @overrides Container.getLeafContainer
     * @returns {Fragment}
     */
    // eslint-disable-next-line class-methods-use-this
    getLeafContainer() {
      return this;
    }

    /**
     * locates the slot config for the slot name. The slot content is typically defined along with the
     * fragment usage.
     * @param {String} name
     * @param {NodeList} defaultTemplates
     * @returns {Promise<{view: *|string, data: Promise<BaseModuleViewModel>}>}
     */
    getSlotConfig(name, defaultTemplates) {
      return Promise.resolve().then(() => {
        const slotViewName = this.getSlotViewLocator(name, defaultTemplates);
        const isDefaultTemplate = slotViewName === `${RESERVED_DEFAULT_SLOT_NAME}:${name}`;
        return {
          view: this.viewTemplateByLocator[slotViewName],
          data: this.getSlotViewModel(isDefaultTemplate),
          alias: this.viewTemplateDataAliasByLocator[slotViewName],
        };
      });
    }

    /**
     * returns the slot view name to use to locate the slot (view) content. The very first time this method is
     * called it locates the slot view content for the provided name and caches it.
     * @param {String} name
     * @param {*} defaultTemplates
     * @returns {*|string}
     */
    getSlotViewLocator(name, defaultTemplates) {
      if (this.getCapability(Constants.FragmentCapability.ALLOWS_SLOTS) && !this.viewTemplateByLocator[name]) {
        if (this.templates && this.templates.length > 0) {
          const tarr = [...this.templates];
          const template = tarr.find((t) => (t.getAttribute('slot') === name));
          if (template) {
            const content = (template && template.innerHTML) || ''; // template.content returns the actual DOM
            if (!content) {
              // it is not that bad for fragment author to not provide a slot content, since we fallback on the default
              this.log.info('Unable to locate the fragment slot content for the slot:', name,
                '. Please check your fragment configuration!');
            }
            this.viewTemplateByLocator[name] = content;
            this.viewTemplateDataAliasByLocator[name] = template.getAttribute('data-oj-as') || '';
          }
        }
      }

      // either a template was provided by user, or if not use defaultTemplate or ''. Default template is also used
      // if the 'allow_slots' capability is disabled. Example if framgent is used inside layout.
      return this.viewTemplateByLocator[name] ? name : (this.getDefaultSlotViewLocator(name, defaultTemplates) || '');
    }

    /**
     * returns default slot view name. The very first time this method is called it locates the default slot view
     * content for the provided name and caches the same.
     * @param name
     * @param defaultTemplates
     * @returns {string}
     */
    getDefaultSlotViewLocator(name, defaultTemplates) {
      const defaultSlotContentLocator = `${RESERVED_DEFAULT_SLOT_NAME}:${name}`;
      if (!this.viewTemplateByLocator[defaultSlotContentLocator]) {
        const t = defaultTemplates && defaultTemplates[0];
        let defaultContent = (t && t.innerHTML);
        if (!defaultContent) {
          // if a default content was not provided we warn because CCA architecture recommends that a default
          // content be provided for every slot.
          defaultContent = '';
          this.log.warn('Unable to locate a default slot content for the slot:', name,
            '. It is recommended to have a default view content. Please check your fragment configuration!');
        }
        this.viewTemplateByLocator[defaultSlotContentLocator] = defaultContent;
        this.viewTemplateDataAliasByLocator[defaultSlotContentLocator] = t && t.getAttribute('data-oj-as');
      }
      return defaultSlotContentLocator;
    }

    /**
     * Unlike the fragment content which are executed in the scope of the fragment's view model, the fragment slot
     * view model is executed in the context of the parent container that defined and provided the content for the
     * slot. Example, bucket-list fragment incldues a CCA that has a 'header' slot. The content for the header slot
     * is provided by the page, which configures some html with access to the page's context. The text and the
     * button access $page and $flow and this content in then injected into the fragment-slot that is itself running
     * in the context of the fragment but the injected content in the parent's. Here $fragment scope is not available to
     * the content.
     *
     * <oj-vb-fragment name="bucket-list" bridge="[[ vbBridge ]]">
     *  <template slot="someName">
     *    <oj-bind-text value="{{ $page.variables.title }}"></oj-bind-text>
     *    <oj-button on-oj-action="{{ $flow.listeners.notify }}"></oj-button>
     *  </template>
     * </oj-vb-fragment>
     * @param {boolean} useFragmentContext true if the fragment view model needs to be returned
     * @returns {*}
     */
    getSlotViewModel(useFragmentContext = false) {
      let errMsg;
      if (useFragmentContext) {
        // when default template is used, the VB view model needs to be the fragment
        return this.getViewModel();
      }

      if (!this.parent) {
        errMsg = `unable to locate the parent containerfor the current fragment ${this.name} to create a view`
          + ' model for the fragment slot';
        throw new Error(errMsg);
      }
      if (this.getCapability(Constants.FragmentCapability.ALLOWS_SLOTS) === true) {
        return this.parent.getViewModel();
      }
      errMsg = `use of slots in the container for the current fragment' ${this.name} is not supported.`
        + ' Please check your configuration.';
      throw new Error(errMsg);
    }

    /**
     * Called when a fragment is being loaded by the CCA, either for the first time or when the name of the
     * fragment to load changes.
     * @return {import("knockout").observable}
     */
    getModuleConfig() {
      // when this method is called we have to run
      // - beforeExit event listeners for the currently loaded / active fragment
      // - if this passes then
      //    - deactivate old fragment whatever that means
      //    - start loading new fragment (loadFragment)
      //      - invoke beforeEnter of the new fragment
      //      - if beforeEnter cancels loading then rollback and go to old fragment
      //      - otherwise activate fragment; return a moduleConfig for the same
      return this.enter();
    }

    /**
     * Returns a scope resolver map where keys are scope names ("global" / "fragment")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      // 'global' and 'application' are the same for base (old style apps). While 'global' refers to unified app in
      // extensions, 'application' refers to the appUI which is not available for fragments in extensions.
      return {
        [Constants.FRAGMENT_PREFIX]: this,
        [Constants.GLOBAL_PREFIX]: this.application,
        [Constants.APPLICATION_PREFIX]: this.application,
      };
    }

    /**
     * creates the view
     * @see Fragment.createModuleConfig
     * @returns {Promise<String>}
     */
    getView() {
      return this.loadFragment().then((results) => results[1]);
    }

    /**
     * Returns the viewModel object for the fragment moduleConfig. The model contains all the accessible $ properties
     * of the fragment container. All properties are initialized and ready to be evaluated in expressions.
     * Extensions have also been applied to the fragment.
     * The model is bound to the fragment view by the VB fragment component (oj-vb-fragment).
     *
     * {
     *   $variables: {}
     *   $constants: {}
     *   $chains: {}
     *   $functions: {}
     *   $listeners: {}
     * }
     *
     * @return {Promise<FragmentModuleViewModel>} a promise that resolve with the module view model instance.
     */
    getViewModel() {
      if (!this.viewModelPromise) {
        // Initialize/Activate the variables before returning the viewModel. initializePromise can return null
        // viewModel if beforeEnter failed or there was some other error.
        this.viewModelPromise = this.getInitializePromise()
          .then(() => BundleUtils.whenBundlesReady())
          .then(() => new FragmentModuleViewModel(this));
      }
      return this.viewModelPromise;
    }

    /**
     * when a fragment variable is marked as writable add a valueChanged listener so that the CCA can be notified of
     * the value change. This is explicitly wired as fragment params are not direct properties on the vb-fragment CCA
     * value changes for whatever reason is not getting written back automatically.
     * @param {Variable} newVariable
     * @param {Object} onValueChangedDef the valueChanged def on the variable
     * @param availableContexts
     */
    addValueChangedListeners(newVariable, onValueChangedDef, availableContexts) {
      const def = newVariable.descriptor;
      // only variables marked writeback:true and input:fromCaller are written back
      if (def && def[Constants.VariableProperties.WRITEBACK]
        && def[Constants.VariableProperties.INPUT] === Constants.VariablePropertyInput.FROM_CALLER) {
        this.handleValueChangedListenerForWritable(newVariable, onValueChangedDef, availableContexts);
      }
      super.addValueChangedListeners(newVariable, onValueChangedDef, availableContexts);
    }

    // eslint-disable-next-line class-methods-use-this
    getVariableDescriptor(variableDef, descriptor) {
      const superDesc = super.getVariableDescriptor(variableDef, descriptor);
      return Object.assign({ writeback: variableDef[Constants.VariableProperties.WRITEBACK] },
        superDesc, descriptor);
    }

    /**
     * custom valueChanged listener that writes back value on fragment CCA mapped variable
     * @param newVariable
     * @param onValueChangedDef
     * @param availableContexts
     */
    handleValueChangedListenerForWritable(newVariable, onValueChangedDef, availableContexts) {
      if (!newVariable.onValueChanged) {
        this.log.warn(newVariable.name, 'does not have a live expression so its onValueChanged listener is ignored');
      }
      const varChangeTracker = {
        eventSource: newVariable.onValueChanged,
        eventListener: (e) => {
          const eventPayload = e || {};
          const context = availableContexts.clone();
          context[Constants.ContextName.EVENT] = e; // capture event payload on the scope
          const eventName = e.type; // vb event type and name is the same
          logger.beforeHandleEvent(this.className, this.id,
            'handling variable event', eventName, 'with payload:', e);
          const mo = new EventMonitorOptions(EventMonitorOptions.SPAN_NAMES.EVENT_VARIABLE, eventName, e, this);
          return this.log.monitor(mo, (eventTime) => {
            // notify the outer context of the value change in current fragment (scope). The container is at most
            // privy to some methods on the fragment bridge, like valueChanged
            const parentBridge = this.parent.fragmentBridge;
            try {
              parentBridge.valueChanged(Object.assign({}, eventPayload, { _vb_fragmentId: this.id }));
              logger.afterHandleEvent(this.className, this.id,
                'handled variable event', eventName, 'successfully', eventTime());
            } catch (error) {
              logger.afterHandleEvent('Failed to handle variable event', eventName, eventTime(error));
              this.log.error(error);
            }
          });
        },
      };
      newVariable.onValueChanged.add(varChangeTracker.eventListener, this);
      this.variablesListeners.push(varChangeTracker);
    }

    /**
     * Initializes the variables defined in the fragment model into the fragment scope, then sets up the context for the
     * same.
     *
     * @returns {Promise} A promise that resolves when complete
     */
    initializeScopeAndContextVariables() {
      const uniqueId = `[${this.id}: ${this.resourceName}]`;

      // Create the fragment variables using the fragment metadata
      return this.loadFragment()
        .then(() => {
          const mo = new ActivateMonitorOptions(
            this.activateSpanName, `fragment activate ${uniqueId}`, this,
          );

          return this.log.monitor(mo, (fragmentLoadTimer) => this.initAllVariableNamespace()
            .then(() => {
              this.log.coralInfo('Fragment', uniqueId, 'of parent', this.parent.id, 'ACTIVATED.', fragmentLoadTimer());
            })
            .catch((error) => {
              const message = (error && error.message) || 'Unknown error';

              this.log.coralInfo('Fragment', uniqueId, 'of parent', this.parent.id, 'failed to activate because of'
                + ' error: ', message, fragmentLoadTimer(error));
              this.dispose();

              throw error;
            }));
        });
    }

    /**
     * Invoke a before event, (either beforeEnter or beforeExit) and return
     * a promise the resolves to true or false depending on the action chain results.
     * @param  {String} eventName the type of event, either Constants.BEFORE_ENTER_EVENT
     * or Constants.BEFORE_EXIT_EVENT.
     * @return {Promise}  a promise that resolves to a boolean true if not cancelled
     */
    invokeBeforeEvent(eventName) {
      // Return the promise so that the outcome can be used to cancel navigation
      return this.invokeEvent(eventName).then((results) => {
        // Traverse the array of result from the execution of all the event
        // promises and look for cancelled result.
        // Check if the type is an array because sometime it returns Constants.NO_EVENT_LISTENER_RESPONSE
        if (Array.isArray(results)) {
          for (let i = 0; i < results.length; i += 1) {
            const { result } = results[i];
            if (result && result.cancelled === true) {
              this.log.info('Loading of fragment', this.fullPath, 'was cancelled by', eventName);
              // TODO: unclear how to handle navigation cases
              // Because on back/forward button, the browser changes the URL immediately, make sure
              // to restore the previous state when the navigation is cancelled.
              return false;
              // return History.restoreStateBeforeHistoryPop().then(() => false);
            }
          }
        }

        return true;
      });
    }

    /**
     * Mostly used to track loading time. It calls loadFragment artifacts. Called only when the name of the
     * fragment to load is known. Typically this happens when the page renders and encounters a <oj-vb-fragment>
     * with a name attribute set. Or when the name is bound to an expression and the name of the fragment to load
     * also changes.
     *
     * @return {Promise} a promise that resolves to a fragment instance or undefined if error. At this stage the
     * fragment has been 'loaded'.
     */
    loadAndStart() {
      const uniqueId = `[${this.id}: ${this.resourceName}]`;
      // Prevent reloading fragment when we are already in the beforeEnterEvent
      if (this.inBeforeEvent === true) {
        // we are here because we are asked to load a new fragment when we are in the middle of loading a different
        // fragment.
        // TODO: need to throttle changes to fragment
        this.log.warn('Attempt to load a new fragment', this.resourceName, 'when a fragment is already'
          + ' being loaded.');
        return Promise.resolve(this);
      }

      this.loadAndStartPromise = this.loadAndStartPromise || Promise.resolve().then(() => {
        // Start the fragment load timer
        const mo = new LoadMonitorOptions(this.loadSpanName, `fragment load ${uniqueId}`, this);

        return this.log.monitor(mo, (fragmentLoadTimer) => this.loadFragment()
          .then((results) => {
            // do a prelim check whether access is allowed
            if (!this.checkFragmentAccess(results[0])) {
              throw new Error(`Fragment ${this.resourceName} does not allow other extensions to reference it. The
                fragment configuration must explicitly allow access for use by another extension.`);
            }
            this.inBeforeEvent = true;

            // invoke the beforeEnter event for the fragment
            return this.invokeBeforeEvent(Constants.BEFORE_ENTER_EVENT)
              .then((result) => {
                let message = 'LOADED.';
                let returnValue = this;

                // result is false when the beforeEnter event cancelled the navigation
                if (result === false) { // || (navContext && navContext.isCancelled())) {
                  message = 'CANCELED.';
                  returnValue = undefined;
                }

                this.log.coralInfo('Fragment', uniqueId, 'of parent', this.parent.id, message, fragmentLoadTimer());
                return returnValue;
              })
              .finally(() => {
                this.inBeforeEvent = false;
              });
          })
          .catch((error) => {
            const message = (error && error.message) || 'Unknown error';

            this.log.coralInfo('Fragment', uniqueId, 'of parent', this.parent.id, 'failed to load because of error: ',
              message, fragmentLoadTimer(error));
            this.dispose();

            throw error;
          })
          .then((result) => {
            // Make sure to clean up the fragment and scope if navigation was cancelled or because of an error
            if (!result) {
              this.dispose();
            }
            return result;
          }));
      });
      return this.loadAndStartPromise;
    }

    /**
     * Load both the descriptor and the markup first and deal with loading errors from both resources. Then start
     * loading the function module.
     * @return {Promise} a promise that resolves with the loaded fragment artifacts, as an array, primarily the
     * descriptor and the template. when descriptor fails to load this rejects with an error
     * @private
     */
    loadFragment() {
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      this.loadFragmentPromise = this.loadFragmentPromise
        || Promise.all([this.loadDescriptor().catch((err) => err), this.loadTemplate()])
          .then((results) => {
            // when the descriptor fails to load, we need to consider that fragment loading has failed because there
            // is no definition! it's ok not to have JS defined!
            // TODO: it seems we could fallback to a noop descriptor and this.definition so downstream code does
            //  not fail, but that's a larger conversation
            if (results[0] instanceof Error) {
              const uniqueId = `[${this.id}: ${this.resourceName}]`;
              throw (new Error(`unable to load the descriptor for fragment ${uniqueId}`));
            }
            // create the facadeContext early, even before the facade; this requires getters
            this.getAvailableContexts();

            // Setup the component event listeners
            this.initializeEvents();

            // initialize action chains
            this.initializeActionChains();

            return results;
          })
          // make sure that the functions are loaded so that they can be used in 'vbBeforeEnter' event. Also load
          // functions after the descriptor as the latter waits for the extensions to be fully loaded.
          .then((results) => this.loadFunctionModule().then(() => results));
      return this.loadFragmentPromise;
    }

    run() {
      return this.getInitializePromise()
        .then(() => {
          // if the fragment has already been 'entered' do not fire a vbEnter event again. This can happen when the
          // fragment that was previously entered, is disconnected and then re-connected - where the fragment isn't
          // disposed and recreated.
          if (this.lifecycleState !== Constants.ContainerState.ENTERED
            && this.fragmentLifecycleState === Constants.FragmentState.MODULE_CONNECTED) {
            this.lifecycleState = Constants.ContainerState.ENTERED;
            return this.invokeEvent(Constants.ENTER_EVENT);
          }
          return Promise.resolve();
        })
        .then(() => {
          // TODO: record a container activated state change; unclear what this is for!
          StateMonitor.recordStateChange(StateMonitor.RuntimeState.FRAGMENT_ACTIVATED, this);
        });
    }

    /**
     * The name of the runtime environment function to be used to load the html
     *
     * @type {String} the template loader function name
     */
    static get templateLoaderName() {
      return 'getFragmentTemplate';
    }

    /**
     * Updates the variables with the new input params that are coming in. We take the value and overwrite the
     * variable default value. If there are live expressions setup on the variable they will no longer work since
     * the value might overwrite that.
     *
     * @param {string} name
     * @param {object} paramValue includes the 'value' property
     */
    updateParam(name, paramValue) {
      // TODO: input param needs to be 're-applied' on the current fragment variables
      this.inputParams[name] = paramValue;

      // update the variable value with the inputParam value
      if (this.scope) {
        const variable = this.scope.getVariable(name);
        if (variable) {
          this.log.info('input param updating fragment variable', variable.name, 'with value', paramValue.value);

          // make the prototype value available to the helper so it can be used for picking arrays
          this.scope.variables[name] = paramValue.value;
        }
      } else {
        this.log.finer('unable to update fragment variable with value', paramValue.value, 'as the fragment scope',
          'has not been created yet');
      }
    }

    /**
     * returns an error message when the options are incorrect. navigateAction within fragment must include the
     * application property in the params
     * @param options
     */
    // eslint-disable-next-line class-methods-use-this
    validateNavigation(options) {
      if (options.operation !== Constants.NavigateOperation.APP_UI) {
        throw new Error('The \'application\' property is required but not set!');
      }

      // Always enforce the page to be navigable when navigating from a fragment. It could be
      // in the same App UI as long as the page is navigable.
      this.application.appUiInfos.validateNavigation(options);
    }
  }

  return Fragment;
});


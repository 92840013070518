'use strict';

define('vb/private/stateManagement/packagePage',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
  'vb/private/stateManagement/pageInExtension',
  'vb/private/stateManagement/packageFragment',
  'vb/private/constants',
], (Mixin, PackageContainerMixin, PackageAndExtensionContainerMixin, PageInExtension, PackageFragment) => {
  /**
   * PackagePage class
   * A class for page object defined in an App UI
   */
  class PackagePage extends Mixin(PageInExtension).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, path, className = 'PackagePage') {
      super({ id, extension: parent.package.extension }, parent, path, className);
    }

    static get FragmentClass() {
      return PackageFragment;
    }

    isDefaultFlowId(id) {
      return this.definition.routerFlow === id || this.definition.defaultFlow === id;
    }

    invokeBeforeEvent(eventName) {
      // Make sure the App UI is initialized before executing the beforeEvent
      return this.package.initialize().then(() => super.invokeBeforeEvent(eventName));
    }

    /**
     * Check if there is any restriction to navigate to this page
     * @param {NavigationContext} navContext
     * @throws an error id the page is not navigable
     */
    checkNavigable(navContext) {
      // Only do the check when it's triggered by a navigation action (navContext is truthy), direct access with
      // a bookmark or URL are not blocked.
      // Also only needs to check the leaf page (leaf pages don't have routerFlow defined) not any other the pages
      // in the container hierarchy.
      if (navContext && !this.definition.routerFlow) {
        // If the destination is in an other App UI, check if the page is navigable
        const startingPackageId = navContext.container
          && navContext.container.package
          && navContext.container.package.id;
        const destinationPackageId = this.package.id;
        // Compare the id instead of the package object because in the switcher case the package object is
        // a different instance.
        if (startingPackageId !== destinationPackageId) {
          if (!this.isNavigable()) {
            throw new Error(`Navigation to ${this.getNavPath()} is not enabled.`);
          }
        }
      }
    }
  }

  return PackagePage;
});

